.widgetTitle {
  font-weight: bold;
}

.downloadIcon svg {
  width: var(--abs-20);
  height: var(--abs-20);
  color: var(--grey-700);
}

.tooltip {
  text-align: start;
}
