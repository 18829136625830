.e-button--default-border:focus,
.e-button--default-border:hover,
.e-button--default-border:visited {
  background: var(--grey-600);
  color: var(--white);
}

.e-button--default-border {
  background: var(--white);
  border-color: var(--grey-700);
  border-bottom-color: var(--grey-800);
  color: var(--grey-800);
}

.e-button--default-border:active {
  background: var(--white);
}
