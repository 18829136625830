/**
 * CSS Variables for font weights.
 */
:root {
  /* normal font weight */
  --font-weight-400: 400;

  /* semi-bold font weight */
  --font-weight-600: 600;

  /* bold font weight */
  --font-weight-700: 700;
}
