/**
 * ---------------------------------------------
 * Specific styles
 * ---------------------------------------------
 */

.c-loading {
  pointer-events: none;
}

.c-loading__full {
  height: var(--gutter);
}

.c-loading--wrapper {
  height: var(--gutter);
  width: 60px;
  margin: 0 auto;
}

.c-loading__right-message {
  line-height: 16px;
}

.c-loading__dot-size {
  width: 61px;
}

.c-loading--dot {
  border-color: var(--white);
  border-width: 2px;
  border-style: solid;
  animation-name: trv-loading;
  height: var(--gutter);
  width: var(--gutter);
  animation-timing-function: ease-in-out;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  border-radius: 100%;
  position: absolute;
}

.c-loading--dot:first-child {
  animation-delay: -0.5s;
}

.c-loading--dot:nth-child(2) {
  animation-delay: -0.4s;
}

.c-loading--dot:nth-child(3) {
  animation-delay: -0.3s;
}

@keyframes trv-loading {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(44px);
  }
  to {
    transform: translateX(0);
  }
}

.c-loading--dark .c-loading--dot:nth-child(1) {
  background-color: var(--grey-600);
}

.c-loading--dark .c-loading--dot:nth-child(2) {
  background-color: var(--grey-500);
}

.c-loading--dark .c-loading--dot:nth-child(3) {
  background-color: var(--grey-300);
}

/* White variant */

.c-loading--white .c-loading--dot:nth-child(1) {
  background-color: var(--white);
  opacity: 0.25;
}

.c-loading--white .c-loading--dot:nth-child(2) {
  background-color: var(--white);
  opacity: 0.5;
}

.c-loading--white .c-loading--dot:nth-child(3) {
  background-color: var(--white);
  opacity: 0.9;
}

.c-loading--white .c-loading--dot {
  border-color: var(--blue-300);
}

.c-loader-ellipsis::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
  animation: ellipsis steps(4, end) 1500ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
