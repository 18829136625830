/**
 * Box shadow variables
 */
:root {
  /* offset-x */
  --box-shadow-x: var(--abs-0);

  /* offset-y */
  --box-shadow-y: var(--abs-1);

  /* blur-radius */
  --box-shadow-blur: var(--abs-4);

  --box-shadow-color-15: rgba(55, 69, 77, 0.15);
  --box-shadow-color-30: rgba(55, 69, 77, 0.3);
  --box-shadow-color-45: rgba(55, 69, 77, 0.45);

  /* color alpha 15% */
  --box-shadow-15: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur)
    var(--box-shadow-color-15);

  /* color alpha 30% */
  --box-shadow-30: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur)
    var(--box-shadow-color-30);

  /* color alpha 45% */
  --box-shadow-45: var(--box-shadow-x) var(--box-shadow-y) var(--box-shadow-blur)
    var(--box-shadow-color-45);
}
