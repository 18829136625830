.main {
  min-height: calc(100vh);
  margin: var(--gutter) auto 7vw;
  max-width: 1400px;
}

@media screen and (max-width: 1920px) {
  .main {
    max-width: 1200px;
  }
}

.eventList {
  min-height: 70vh;
  align-items: center;
}

.loading {
  padding: var(--gutter--tiny);
  padding-top: 25vh;
}

.retryButton {
  margin-top: var(--gutter--small);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  top: var(--gutter--medium);
  align-items: center;
}

.tabContent {
  padding-top: var(--gutter--small);
}

.eventSection {
  box-shadow: var(--box-shadow);
  background: var(--white);
  height: 100%;
}

.headerTabWrapper {
  border-color: var(--grey-300);
  border-style: solid;
  border-width: 0 0 1px;
  padding: var(--gutter) var(--gutter--large);
}
