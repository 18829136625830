.u-margin--gutter {
  margin: var(--gutter);
}

.u-margin--gutter--huge {
  margin: var(--gutter--huge);
}

.u-margin--gutter-large {
  margin: var(--gutter--large);
}

.u-margin-w--gutter {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.u-margin-right--huge {
  margin-right: var(--gutter--huge);
}

.u-margin-right--large {
  margin-right: var(--gutter--large);
}

.u-margin-right--gutter {
  margin-right: var(--gutter);
}

.u-margin-left--gutter {
  margin-left: var(--gutter);
}

.u-margin-left--gutter--small {
  margin-left: var(--gutter--small);
}

.u-margin-left--gutter--medium {
  margin-left: var(--gutter--medium);
}

.u-margin-left--gutter--large {
  margin-left: var(--gutter--large);
}

.u-margin-right--gutter--small {
  margin-right: var(--gutter--small);
}

.u-margin-right--medium {
  margin-right: var(--gutter--medium);
}

.u-margin-right--small {
  margin-right: var(--gutter--small);
}

.u-margin-right--tiny {
  margin-right: var(--gutter--tiny);
}

.u-margin-left--small {
  margin-left: var(--gutter--small);
}

.u-margin-left--tiny {
  margin-left: var(--gutter--tiny);
}

.u-margin-left--large {
  margin-left: var(--gutter--large);
}

.u-margin-left--huge {
  margin-left: var(--gutter--huge);
}

.u-margin-left--medium {
  margin-left: var(--gutter--medium);
}

.u-margin-right--tiny {
  margin-right: var(--gutter--tiny);
}

.u-margin-right--t-small {
  margin-right: var(--gutter--t-small);
}

.u-margin-left--t-small {
  margin-left: var(--gutter--t-small);
}

.u-margin-bottom--t-small {
  margin-bottom: var(--gutter--t-small);
}

.u-margin-left--auto {
  margin-left: auto;
}

.u-margin-top--auto {
  margin-top: auto;
}

.u-margin-top--small {
  margin-top: var(--gutter--small);
}

.u-margin-top--tiny {
  margin-top: var(--gutter--tiny);
}

.u-margin-top--zero {
  margin-top: 0;
}

.u-margin-top--medium {
  margin-top: var(--gutter--medium);
}

.u-margin-top--gutter {
  margin-top: var(--gutter);
}

.u-margin-top--huge {
  margin-top: var(--gutter--huge);
}

.u-margin-bottom--huge {
  margin-bottom: var(--gutter--huge);
}

.u-margin-bottom--gutter {
  margin-bottom: var(--gutter);
}

.u-margin-bottom--gutter--small {
  margin-bottom: var(--gutter--small);
}

.u-margin-bottom--medium {
  margin-bottom: var(--gutter--medium);
}

.u-margin-top--large {
  margin-top: var(--gutter--large);
}

.u-margin-bottom--large {
  margin-bottom: var(--gutter--large);
}

.u-margin-bottom--zero {
  margin-bottom: 0;
}

.u-margin--zero {
  margin: 0;
}
