@import '~react-day-picker/lib/style.css';

.c-datePicker {
  position: absolute;
  z-index: 1;
}

.c-datePicker .c-datePicker__input,
.c-datePicker .c-datePicker__select {
  height: 36px;
}

.c-datePicker .c-datePicker__input {
  background-color: var(--white);
  width: 120px;
}

.c-datePicker .c-datePicker__input--full-width {
  width: 100%;
}

.c-datePicker .c-datePicker__input--disabled {
  background-color: var(--grey-200);
}

.c-datePicker__input--invalid {
  border: 1px solid var(--red-700) !important;
  background-color: var(--red-400) !important;
}

.c-datePicker__input--is-focus {
  /*
    TODO colours var(--blue-300) and var(--blue-400) should be reviewed due to the default style for focus depends on the browser
    and in the OS. This means that this colour does not fit in all the browsers/OS we are mantaining. Also
    this should be added to variables.
  */
  box-shadow: 0px 0px 0px 3px var(--blue-300);
  border: 2px solid var(--blue-400) !important;
}

.datePickerModal {
  background: var(--grey-800);
  opacity: 0.5;
}

.datePickerSelector {
  background-color: var(--white);
  top: 62px;
  position: absolute;
  left: 493px;
}

.datePickerSelector > .c-composedDropdown {
  background: var(--white);
}

.datePickerCTA {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--abs-12);
}

/**
 * Overwrite styles
 */

.DayPicker-wrapper {
  padding-bottom: 0;
}

.Selectable .DayPicker-Day--selected {
  background-color: transparent !important;
  z-index: 1;
}

.Selectable .DayPicker-Day--start::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--blue-300);
  z-index: -2;
  margin-left: 10px;
}

.Selectable .DayPicker-Day--start::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--blue-700);
  z-index: -1;
}

.Selectable .DayPicker-Day--end::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -10px;
  background-color: var(--blue-300);
  z-index: -2;
}

.DayPicker-Day--outside::after,
.DayPicker-Day--outside::before {
  content: '';
  display: none;
}

.Selectable .DayPicker-Day--end::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--blue-700);
  z-index: -1;
}

.DayPicker-Body {
  background-color: var(--white);
  display: grid;
  padding: 14px;
  grid-template-columns: 1fr;
}

.DayPicker-Week,
.DayPicker-WeekdaysRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.DayPicker-Day--start.DayPicker-Day--end::after {
  display: none;
}

.DayPicker-Caption {
  padding: 10px 5px;
  text-align: center;
  width: auto;
  font-size: 14px;
  color: var(--grey-700);
  background-color: var(--grey-200);
  margin-bottom: 0;
  z-index: -1;
}

.DayPicker-Month:first-of-type .DayPicker-Caption > div {
  position: relative;
}

.DayPicker-Month:first-of-type .DayPicker-Caption > div:after {
  content: ' ';
  position: absolute;
  right: -5px;
  top: 0;
  height: 60px;
  border: 1px solid var(--grey-500);
}

.DayPicker-Month:first-of-type > .DayPicker-Body {
  position: relative;
}

.Selectable .DayPicker-Month:first-of-type > .DayPicker-Body:after {
  content: ' ';
  position: absolute;
  right: 0px;
  top: 15px;
  height: 170px;
  border: 1px solid var(--grey-200);
}

.DayPicker-Months {
  border: solid 1px var(--grey-500);
}

.DayPicker-Month {
  margin: 0;
  width: 260px;
}

.DayPicker-WeekdaysRow {
  background-color: var(--grey-200);
  margin-top: 0;
  padding: 12px 14px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.DayPicker-Weekdays {
  margin: 0;
  display: block;
}

.DayPicker-Weekday {
  font-size: 8px;
  color: var(--grey-500);
  text-transform: uppercase;
  padding: 0;
  display: block;
}

.DayPicker-Day {
  padding: 0;
  height: 24px;
  font-size: 12px;
  color: var(--grey-700);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--abs-0);
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(
    .DayPicker-Day--outside
  ) {
  background-color: var(--blue-300) !important;
  color: var(--grey-700) !important;
  border-radius: 0;
}

.DayPicker-Day--outside {
  background: var(--white) !important;
  color: var(--grey-300) !important;
  /* we don't have this color! */
}

.DayPicker-Week {
  margin: 5px 0px;
}

.DayPicker-NavButton--prev {
  left: 20px;
  z-index: 1;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMzNzQ1NGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik05LjUgN2w1IDVtLTUgNWw1LTUiIGNsYXNzPSJzdmctY29sb3ItLXByaW1hcnkiLz48L3N2Zz4=');
  transform: rotate(180deg);
  background-size: 100%;
}

.DayPicker-NavButton--next {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMzNzQ1NGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik05LjUgN2w1IDVtLTUgNWw1LTUiIGNsYXNzPSJzdmctY29sb3ItLXByaW1hcnkiLz48L3N2Zz4=');
  background-size: 100%;
}

.DayPicker-Day--lastWeek {
  background-color: var(--orange-300);
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: transparent;
  z-index: 1;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: transparent !important;
  z-index: -2;
  margin-left: 10px;
}

.DatePicker.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover:before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--blue-300);
  z-index: -1;
}

.DayPicker-Day--disabled {
  color: var(--grey-500);
}
