input.c-panel-control__numberinput {
  width: 90px;
  height: 36px;
  margin-top: 0;
  margin-bottom: 0;
}

input.c-panel-control__numberinput-larger {
  width: 107px;
}

input.c-panel-control__numberinput-error {
  border: 1px solid var(--red-700);
  background-color: var(--red-200);
}

input.c-panel-control__numberinput-error:focus {
  outline: none;
}

.c-panel-control__faq-circle-icon {
  right: -17px;
}

.c-panel-control__button-disabled-toltip {
  right: 24px;
  top: -36px;
}

.c-panel-control__selection-information {
  white-space: pre;
}

.c-panel-control__icn {
  height: 18px;
  width: 18px;
}

.c-panel-control__icn-leading {
  transform: rotate(180deg);
}

.c-panel-control__icon-dropdown {
  height: 20px;
  width: 20px;
}

.c-panel-control__information {
  background-color: var(--blue-300);
}

.c-panel-control__separator {
  height: 44px;
  width: 1px;
  transform: scaleY(-1);
  background-color: var(--blue-300);
}

.c-panel-control__lock {
  font-size: 14px;
}

.error-hint {
  position: absolute;
  display: none;
  z-index: 4;
  margin-top: -10px;
  padding: 8px;
  box-shadow: var(--box-shadow-45);
  border: 1px solid var(--red-700);
  background-color: var(--white);
  background-position: 8px 12px;
  color: var(--red-700);
  font-size: 14px;
}

.has-tooltip:hover .error-hint,
.has-tooltip:focus .error-hint,
.has-tooltip:active .error-hint,
.has-tooltip.active .error-hint {
  display: inline-block;
}

.error-hint.block {
  font-weight: 400;
}

.error-hint:after {
  left: 15px;
  top: -6px;
  z-index: 3;
  display: block;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: var(--white);
  border: 1px solid var(--red-700) !important;
}

.error-hint:before {
  top: 0;
  z-index: 4;
  left: 12px;
  width: 18px;
  height: 8px;
  background: var(--white) !important;
}
