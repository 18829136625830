.u-white-space--pre-wrap {
  white-space: pre-wrap;
}

.u-white-space--normal {
  white-space: normal;
}

.u-white-space--no-wrap {
  white-space: nowrap;
}
