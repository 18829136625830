@import '~react-day-picker/lib/style.css';

.c-datePicker .c-datePicker__input:focus {
  box-shadow: 0px 0px 0px 3px var(--blue-300);
  border: 2px solid var(--blue-400) !important;
}

.DatePickerWithCompare .DayPicker-Months {
  border: none;
}

.c-date-period-marker {
  width: 2px;
  height: 36px;
  border-radius: 3px;
  background: var(--blue-700);
  margin: 4px 8px 0px 0px;
  padding-left: 2px;
}

.DatePickerWithCompare .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
  color: var(--grey-700) !important;
  z-index: 1;
}

.DatePickerWithCompare .DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside),
.DatePickerWithCompare .DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside) {
  color: white !important;
}

.DatePickerWithCompare .DayPicker-Day--start:not(.DayPicker-Day--outside) {
  color: white !important;
  z-index: 1;
}

.DatePickerWithCompare .DayPicker-Day--start::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: -2;
  margin-left: 16px;
  background-color: var(--selected-color-light);
}

.DatePickerWithCompare .DayPicker-Day--start::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--selected-color);
  z-index: -1;
}

.DatePickerWithCompare .DayPicker-Day--end::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  margin-left: -16px;
  z-index: -2;
  background-color: var(--selected-color-light);
}

.DatePickerWithCompare .DayPicker-Day--end::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--selected-color);
  z-index: -1;
}

.DatePickerWithCompare
  .DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: var(--selected-color-light) !important;
  z-index: -2;
  margin-left: 10px;
}

.DatePickerWithCompare
  .DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):not(
    .DayPicker-Day--outside
  ):hover::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: var(--selected-color-light) !important;
  z-index: -1;
}

.u-left-round-border {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.u-right-round-border {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: transparent !important;
}

.DatePickerWithCompare
  .DayPicker-Day--selected:not(.DayPicker-Day--end):not(.DayPicker-Day--start):not(
    .DayPicker-Day--outside
  ) {
  background-color: var(--selected-color-light) !important;
}
