/**
 * Container sizes
 *
 * TBD:
 * - native css variables can not be used in media queries
 * - consider using only css container queries
 */
:root {
  /* min container size: 320px */
  --container-320: 320px;

  /* container size: 360px */
  --container-360: 360px;

  /* container size: 412px */
  --container-412: 412px;

  /* container size: 480px */
  --container-480: 480px;

  /* container size: 600px */
  --container-600: 600px;

  /* container size: 720px */
  --container-720: 720px;

  /* container size: 768px */
  --container-768: 768px;

  /* container size: 880px */
  --container-880: 880px;

  /* container size: 980px */
  --container-980: 980px;

  /* container size: 1280px */
  --container-1280: 1280px;

  /* container size: 1600px */
  --container-1600: 1600px;
}
