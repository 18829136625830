.e-button--warning:active,
.e-button--warning:focus,
.e-button--warning:hover,
.e-button--warning:visited {
  background: var(--orange-800);
}

.e-button--warning {
  background: var(--orange-700);
  border-color: var(--orange-700);
  border-bottom-color: var(--orange-800);
}

.e-button--warning[disabled] {
  background: var(--orange-400);
  border-color: var(--orange-400);
  border-bottom-color: var(--orange-700);
}
