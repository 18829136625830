html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 62.5%;
}

#root {
  min-height: 100vh;
}

a {
  color: var(--blue-700);
}

/* overwrite css-utils */

.u-color--blue-very-light {
  color: var(--blue-300);
}

.u-font-family-arial {
  font-family: Arial;
}

input:focus {
  border: 2px solid var(--blue-400);
  outline: none;
}
