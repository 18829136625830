.c-metrics_picker svg {
  visibility: hidden;
}

.c-metrics_picker:hover svg {
  visibility: visible;
}

.c-metrics-picker:hover {
  background-color: var(--grey-200);
}

.c-metrics_picker__menu-header {
  padding-top: var(--gutter--medium);
  padding-left: var(--gutter--medium);
  padding-right: var(--gutter--medium);
}

.c-metrics_picker__menu {
  padding-left: var(--gutter--medium);
  padding-right: var(--gutter--medium);
  padding-bottom: var(--gutter--medium);
  max-height: 440px;
  overflow-y: scroll;
}

.c-metrics_picker__menu li {
  border-bottom: 1px solid var(--grey-200);
}

.c-metrics_picker__menu li:first-child {
  border-top: 1px solid var(--grey-200);
}
