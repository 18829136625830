.c-bm-control-panel {
  display: block;
}

.c-bm-control-panel__icon {
  width: 18px;
}

.c-bm-control-panel__upper-container {
  display: flex;
  height: 52px;
  line-height: 52px;
  background-color: var(--blue-200);
  justify-content: space-between;
  padding: 0 var(--gutter);
}

.c-bm-control-panel__current-value {
  margin-right: var(--gutter);
}

.c-bm-control-panel__lower-container {
  display: block;
  height: 34px;
  background-color: var(--blue-300);
  padding: var(--gutter--small) var(--gutter);
  font-size: var(--font-size--tiny);
}

input.c-bm-control-panel__number-input {
  width: 90px;
  height: 36px;
  margin-top: 0;
  margin-bottom: 0;
}

input.c-bm-control-panel__number-input-error {
  border: 1px solid var(--red-700);
  background-color: var(--red-200);
}

input.c-bm-control-panel__number-input-error:focus {
  outline: none;
}
