.u-padding--zero {
  padding: 0;
}

.u-padding--gutter--large {
  padding: var(--gutter--large);
}

.u-padding--gutter--huge {
  padding: var(--gutter--huge);
}

.u-padding--gutter--medium {
  padding: var(--gutter--medium);
}

.u-padding--gutter-small {
  padding: var(--gutter--small);
}

.u-padding-top--gutter {
  padding-top: var(--gutter);
}

.u-padding-right--gutter--small {
  padding-right: var(--gutter--small);
}

.u-padding-left--gutter--small {
  padding-left: var(--gutter--small);
}

.u-padding-top--gutter--small {
  padding-top: var(--gutter--small);
}

.u-padding-top--gutter--t-small {
  padding-top: var(--gutter--t-small);
}

.u-padding-top--gutter--medium {
  padding-top: var(--gutter--medium);
}

.u-padding-bottom--gutter--medium {
  padding-bottom: var(--gutter--medium);
}

.u-padding-top--huge {
  padding-top: var(--gutter--huge);
}

.u-padding-top--gutter--large {
  padding-top: var(--gutter--large);
}

.u-padding-bottom--gutter--large {
  padding-bottom: var(--gutter--large);
}

.u-padding-bottom--gutter--small {
  padding-bottom: var(--gutter--small);
}

.u-padding-left--zero {
  padding-left: 0;
}

.u-padding-bottom--zero {
  padding-bottom: 0;
}

.u-padding-horizontal--s-large {
  padding-left: 20px;
  padding-right: 20px;
}

.u-padding-vertical--t-medium {
  padding-top: 14px;
  padding-bottom: 14px;
}

.u-padding--t-medium {
  padding: 14px;
}

.u-padding--gutter--medium {
  padding: var(--gutter--medium);
}

.u-padding-left--huge {
  padding-left: var(--gutter--huge);
}

.u-padding-right--huge {
  padding-right: var(--gutter--huge);
}

.u-padding-bottom--huge {
  padding-bottom: var(--gutter--huge);
}

.u-padding-right--minuscule {
  padding-right: var(--gutter--minuscule);
}

.u-padding-right--extra-huge {
  padding-right: calc(var(--gutter) * 3);
}

.u-padding-w--gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.u-padding-h--gutter {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}

.u-padding-h--small {
  padding-top: var(--gutter--small);
  padding-bottom: var(--gutter--small);
}

.u-padding-vertical--small {
  padding-top: var(--gutter--small);
  padding-bottom: var(--gutter--small);
}

.u-padding-h--large {
  padding-top: var(--gutter--large);
  padding-bottom: var(--gutter--large);
}

.u-padding-w--tiny {
  padding-right: var(--gutter--tiny);
  padding-left: var(--gutter--tiny);
}

.u-padding-w--large {
  padding-right: var(--gutter--large);
  padding-left: var(--gutter--large);
}

.u-padding-bottom-medium {
  padding-bottom: 14px;
}
