.chart-builder--chart ul li.recharts-legend-item > svg.recharts-surface {
  margin-right: 8px !important;
}

.chart-builder--chart li.recharts-legend-item {
  padding: 6px;
  margin-right: 4px !important;
  font-size: 12px;
  height: 30px;
  border-radius: 2px;
  display: inline-flex !important;
  align-items: baseline;
}

.chart-builder--chart li.recharts-legend-item:hover {
  cursor: pointer;
  background: var(--grey-200);
}

.chart-builder--chart tspan {
  font-size: 12px !important;
}

.chart-builder--chart div.recharts-wrapper > svg.recharts-surface {
  margin-top: 45px !important;
}

.chart-builder--chart span.recharts-legend-item-text {
  display: inline-flex;
}

.chart-builder--chart span.recharts-legend-item-text span.short-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 50px;
  display: block;
  text-align: left;
}

.chart-builder--chart span.recharts-legend-item-text span.item-text {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: left;
}

.chart-builder--chart ul.recharts-default-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.chart-builder--chart.no-data li.recharts-legend-item:hover {
  cursor: default;
  background: transparent;
}

.chart-builder--chart.no-data div.recharts-wrapper > svg.recharts-surface {
  margin-top: auto !important;
}

.recharts-legend-item-text > div {
  padding-top: 0 !important;
}
