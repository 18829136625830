.grid {
  max-width: 100%;
  max-height: calc(100vh - 400px);
  min-height: 200px;
  /* max-height: calc(100vh - 335px); // TODO necessary changes to make the footer visible on the second fold*/
  /* TODO define it as a constant */
  position: relative;
}

.scrollbar {
  overflow: overlay;
  overflow-x: scroll;
}

.scrollbar.hidden {
  overflow: hidden;
}

.grid::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.grid::-webkit-scrollbar:hover {
  background-color: var(--grey-200);
}

.grid::-webkit-scrollbar-thumb {
  border: 2px solid var(--transparent);
  background-clip: padding-box;
  -webkit-border-radius: 4px;
  background-color: var(--grey-300);
}

.grid::-webkit-scrollbar-thumb:active {
  background-color: var(--grey-500);
}

.table {
  border-collapse: separate;
  border-spacing: 0;
}

.fixedLayout {
  table-layout: fixed;
}

.noFooter tr:last-child td {
  border-bottom: 1px solid var(--grey-300);
}

.flexibleHeight {
  max-height: 100%;
}
