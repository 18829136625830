.c-reports-tab {
  margin: var(--gutter) auto 7vw;
  max-width: 1400px;
}

.c-reports-type-list__container {
  margin-bottom: 42px;
}

.c-reports-type-list__title {
  font-size: 16px;
}
