.c-area-options {
  overflow-y: scroll;
  width: 100%;
  height: 200px;
  background: var(--white);
}

.c-area-options__options {
  height: 300px;
}
