/**
 * Z-index values for the application.
 *
 * @description
 * This block defines CSS custom properties (variables) for standardizing z-index values across the application.
 * The z-index values are named according to their purpose for easy understanding.
 * Range: 0 - 5
 */
:root {
  /* Represents the z-index value for the base layer. */
  --z-index-0: 0;

  /* Represents the z-index value for the layer above the base layer. */
  --z-index-1: 1;

  /* Represents the z-index value for the layer above z-index-1. */
  --z-index-2: 2;

  /* Represents the z-index value for the layer above z-index-2. */
  --z-index-3: 3;

  /* Represents the z-index value for the layer above z-index-3. */
  --z-index-4: 4;

  /* Represents the z-index value for the layer above z-index-4. */
  --z-index-5: 5;
}
