.buttonDropdown {
  position: relative;
}

.buttonDropdownPopup {
  top: 110%;
  width: max-content;
  min-width: 216px;
  box-shadow: var(--box-shadow);
  background: var(--white);
  border-radius: var(--abs-2);
  padding: var(--abs-4);
  position: absolute;
  z-index: var(--z-index-5);
}
.right .buttonDropdownPopup {
  right: 0;
}

.left .buttonDropdownPopup {
  left: 0;
}

.buttonDropdownPopup li {
  padding: var(--gutter--medium) var(--gutter);
  cursor: pointer;
}

.buttonDropdownPopup li:hover {
  background-color: var(--grey-200);
}

.buttonDropdownUploadItem {
  cursor: pointer;
  display: flex;
  min-height: 48px;
  line-height: 48px;
  font-size: var(--font-size--h-tiny);
  padding: 0 !important;
}

.buttonDropdownUploadItem input {
  display: none;
}

.buttonDropdownDisabled {
  cursor: default;
  color: var(--grey-500);
}
.tertiaryButton {
  border-color: var(--grey-400);
}
.tertiaryButton:hover,
.tertiaryButton:hover svg {
  background: transparent !important;
  color: var(--grey-700) !important;
  border-color: var(--grey-700) !important;
}
