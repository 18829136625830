.c-user-menu__account {
  font-style: normal;
  width: 36px;
  text-align: center;
  line-height: 36px;
}

.has-popover {
  position: relative;
  /*
       * ### bugfix for siteheader-controll (button) and popover center
       */
  text-align: inherit;
}
