.u-background--whitesmoke {
  background-color: var(--grey-200);
}

.u-background--fafafa {
  background-color: var(--white);
}

.u-background--inherit {
  background: inherit;
}

.u-background--juri-lightest {
  background: var(--grey-200);
}

.u-background-juri-almost-white {
  background: var(--grey-200);
}
