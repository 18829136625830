.container {
  display: flex;
  background-color: var(--blue-700);
  align-items: center;
  columns: var(--white);
  padding: var(--abs-12);
  gap: var(--abs-12);
  max-width: 320px;
  color: var(--white);
  border-radius: var(--abs-2);
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--abs-12);
}

.content > button {
  color: var(--white);
}

.content > button > svg {
  background-color: transparent !important;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: var(--rel-100);
}

.footer button {
  color: var(--white);
}

.nextButton,
.nextButton:hover {
  background: var(--transparent);
  padding: var(--abs-0);
  height: auto;
  border: var(--abs-0);
  border-bottom: 1px solid var(--white);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
}
