.widgetTitleTooltip {
  margin-right: 6%;
  margin-top: -10px;
}

.widgetTitleTooltipParent {
  width: 90%;
}

.widgetTitle {
  font-weight: var(--font-weight-700);
  height: var(--abs-36);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  display: inline-block;
}

.widgetTitleEdit {
  transform: translateY(-7.5px);
  width: 100%;
}

.noDataWrapper {
  position: relative;
  top: 40%;
  display: flex;
}

.timeLineWidget :global(.c-widget-header) {
  margin-bottom: 0;
}

:global(.recharts-cartesian-axis-ticks) {
  font-size: 10px;
}

:global(.recharts-cartesian-axis-tick-line) {
  display: none;
}
