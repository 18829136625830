.c-partnerSelector:hover {
  background-color: var(--grey-200);
  border-radius: 2px;
}

.c-partnerSelector__menu {
  max-height: 440px;
  width: 312px;
  overflow-y: scroll;
}

.c-partnerSelector__menu__list li:hover {
  background-color: var(--grey-200);
}

.c-input--big ~ .c-inputIcon {
  top: 10px;
}

.c-partnerSelector__menu input {
  margin-bottom: 9px;
}
