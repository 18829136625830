.c-bell-and-notification-drawer {
  height: 40px;
}

.c-bell-and-notification-drawer:hover {
  background-color: var(--grey-200);
  border-radius: 2px;
}

.c-bell-and-notification-drawer__bell {
  left: 13px;
  bottom: 34px;
}
