.c-header {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: var(--white);
}

.c-header__logo {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.c-header__banner {
  background-color: var(--white);
}
