.c-snackbar-notification {
  width: 296px;
  min-height: 65px;
  border-radius: 2px;
  font-size: 12px;
}

.c-snackbar-notification__close {
  height: 18px;
  width: 18px;
}
