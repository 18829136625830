.c-widget-table td {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
}

.c-widget-table tr {
  border-bottom: 1px solid var(--grey-200);
}

.c-widget-table tr:hover {
  background-color: var(--color-white);
}
