.activeFiltersHeader {
  padding-right: var(--gutter--medium);
  color: var(--grey-600);
}

.clearFiltersBtn {
  padding: 0px 4px !important;
  color: var(--blue-700);
  font-weight: var(--font-weight-400);
  white-space: nowrap;
}

.tags {
  display: flex;
  align-items: center;
  gap: var(--abs-8);
  margin-left: var(--abs-8);
  overflow-x: scroll;
  flex-wrap: nowrap;
  scrollbar-width: none;
}

.rightScrollButton > button:last-child,
.rightScrollButton > div::after {
  right: var(--abs-100) !important;
}

.selectedFocus {
  border-color: var(--blue-500);
}

.focus {
  border-color: var(--grey-700);
}
