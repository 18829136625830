.e-button--danger:active,
.e-button--danger:focus,
.e-button--danger:hover,
.e-button--danger:visited {
  background: var(--red-800);
  border-color: var(--red-800);
}

.e-button--danger {
  background: var(--red-700);
  border-color: var(--red-700);
  border-bottom-color: var(--red-800);
}

.e-button--danger[disabled] {
  background: var(--red-500);
  border-color: var(--red-500);
  border-bottom-color: var(--red-700);
}
