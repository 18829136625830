.contextMenu {
  display: inline-flex;
}

.showContextMenu > span > span {
  visibility: visible !important;
  vertical-align: top;
}
.showContextMenu > span > span > div {
  visibility: visible !important;
  vertical-align: top;
}

.contextMenuTable:hover {
  background-color: var(--grey-200) !important;
}

.contextMenuTable td:last-child {
  padding-inline: var(--abs-12);
}

.showContextMenu svg {
  top: 0;
}
.contextMenuTable:hover {
  outline: 1px solid var(--grey-200);
  border-radius: var(--abs-2);
}

.textCell {
  display: flex;
  align-items: center;
  gap: var(--abs-8);
}
