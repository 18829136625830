.gridRow {
  background: white;
  z-index: 1;
  text-overflow: clip;
  max-width: 250px;
  border-bottom: none !important;
  border-right: 1px solid var(--grey-300);
  height: var(--abs-36);
  vertical-align: middle;
}

.cell {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-block: var(--abs-0);
  vertical-align: middle;
  height: var(--abs-36);
}

.gridRow:not(:last-child) .cell {
  border-bottom: 1px solid var(--grey-200) !important;
}

.rightAlignedColumn {
  text-align: right;
}

.selected {
  background: var(--blue-200) !important;
}

.selected:hover {
  background: var(--blue-300) !important;
}

.sticky {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  text-overflow: ellipsis;
  background: inherit;
  left: 0;
  z-index: 1;
}

.lastStickyColumn {
  border-right: 1px solid var(--grey-300);
}

.checkbox {
  padding: 0 !important;
}

.cell:not(.checkbox) {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.gridRow.accordion {
  background-color: var(--grey-100);
  cursor: pointer;
}

.gridRow.accordion .cell {
  height: 35px !important;
  vertical-align: middle;
}

.bold * {
  font-weight: var(--font-weight-700);
}

.large {
  height: 26px;
  line-height: 26px;
}

.checkbox label {
  padding: 0 16px;
}

.large.checkbox label {
  padding-top: 14px;
  padding-bottom: 14px;
}

.gridRow:not(:last-child) {
  border-bottom: 1px solid var(--grey-200);
}

.gridRow:hover {
  background: var(--grey-200);
}
