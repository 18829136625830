.menu {
  background-color: var(--white);
  display: inline-block;
  border-radius: var(--abs-2);
  box-shadow: var(--box-shadow-45);
  padding: var(--abs-4);
}

.menuItem {
  display: flex;
  align-items: center;
  gap: var(--abs-8);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-150);
  padding-inline: var(--abs-8);
  height: 44px;
  cursor: pointer;
  border-radius: var(--abs-2);
  color: var(--grey-800);
}

.menuItem:hover,
.active {
  background-color: var(--grey-200);
}

.disabled {
  color: var(--grey-300);
  cursor: not-allowed;
}

.icon {
  flex-shrink: 0;
  color: var(--grey-800);
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: var(--rel-100);
}

.nestedMenu {
  width: 200px;
}

.title {
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-700);
  padding-inline: var(--abs-8);
  color: var(--grey-800);
  display: flex;
  align-items: center;
  height: var(--abs-24);
}

.iconContainer {
  display: inline-flex;
  flex-direction: row;
  width: var(--rel-100);
  align-items: center;
}

.rightIcon {
  flex-direction: row-reverse;
  justify-content: space-between;
}
