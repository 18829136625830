.c-grid-loading__wrapper {
  height: var(--gutter);
  width: 60px;
  margin: 0 auto;
}

.c-grid-loading__dot {
  border-color: var(--white);
  border-width: 2px;
  border-style: solid;
  animation-name: trv-loading;
  height: var(--gutter);
  width: var(--gutter);
  animation-timing-function: ease-in-out;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
  border-radius: 100%;
  position: absolute;
}

.c-grid-loading__dot:first-child {
  animation-delay: -0.5s;
}

.c-grid-loading__dot:nth-child(2) {
  animation-delay: -0.4s;
}

.c-grid-loading__dot:nth-child(3) {
  animation-delay: -0.3s;
}

@keyframes trv-loading {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(44px);
  }
  to {
    transform: translateX(0);
  }
}
