.columnTitleWrapper {
  display: flex;
  align-items: center;
}

.columnTitleWrapper:not(.sticky).leftAlign {
  justify-content: flex-start;
}

.columnTitleWrapper:not(.sticky) {
  justify-content: flex-end;
}

.columnTitleWrapper button {
  opacity: 0;
}

.columnTitleWrapper:hover button,
.showSortButton button {
  opacity: 1;
}

.sortButton.leftAlign {
  margin-left: var(--gutter--tiny);
}

.sortButton:not(.leftAlign) {
  margin-right: var(--gutter--tiny);
}

.column:not(.sortable) {
  line-height: 26px;
}

.tooltip.sorted {
  margin-top: 8px;
}

.tooltip:not(.sorted) {
  margin-top: 5px;
}

.columnTitle.sortable {
  cursor: pointer;
}

.columnTitle:not(.sortable) {
  cursor: default;
}

.column.sorted {
  color: var(--grey-700) !important;
}

.column.sticky {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  z-index: 3 !important;
}

.column.hasTooltip {
  z-index: 4 !important;
}

.column {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  color: var(--grey-500);
  min-width: 20px;
  background-color: white;
  z-index: var(--z-index-2);
  text-overflow: clip;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-block: var(--abs-0);
  border-left-width: 0;
  border-right-width: 0;
}

.column.sortable:hover {
  color: var(--grey-700);
}

.column.checkbox {
  border-right-width: 1px !important;
  padding-left: 15px;
  padding-right: 15px;
  width: 45px;
}

.column:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border-left-width: 1px;
}

.column:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-right-width: 1px;
}

.gridHeader tr {
  border-left-width: 1px;
  border-right-width: 1px;
}

.column.narrow.checkbox {
  width: 45px !important;
}

.column.sticky.narrow:not(.checkbox) {
  width: 400px !important;
}

/* last sticky column */
.column.sticky:has(+ :not(.sticky)) {
  border-right-width: 1px !important;
  max-width: 160px !important;
  width: 100px;
}

.tooltip {
  width: max-content;
  max-width: 300px;
  z-index: var(--z-index-5);
}
