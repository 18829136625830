.accordionArrowIcon {
  transition: 0.2s;
  vertical-align: middle;
}

.accordionArrowIcon.expandedRow {
  transform: rotate(90deg);
}

.accordionGridCellContent {
  padding-left: 8px;
  vertical-align: middle;
}

.linkGridCell {
  cursor: pointer;
}

.linkGridCell:hover {
  text-decoration: underline;
}

.checkboxGridCell {
  display: flex;
}
