.c-date-picker-dropdown {
  color: var(--grey-700);
}

.c-date-picker-dropdown__dropdown-extra {
  padding-top: 0px;
  padding-left: 0px;
}

.c-date-picker-dropdown__dropdown {
  width: 266px;
  height: 36px;
}

.c-date-picker-dropdown__text {
  padding-top: 7px;
  color: var(--grey-700);
}

.c-date-picker-dropdown__opened {
  height: 38px;
  width: 269px;
  border: 2px solid var(--blue-400);
}

.c-date-picker-dropdown__left-alignment {
  right: 567px;
}

.c-date-picker-dropdown__alignment {
  width: 1000px;
  position: relative;
}

.c-date-picker-dropdown__right-alignment {
  right: 16px;
}
