.c-widget-row td {
  width: 100%;
}

.c-widget-row td:not(:first-child) {
  text-align: right;
}

.c-widget-row svg {
  position: relative;
}
