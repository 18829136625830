.e-button--success:active,
.e-button--success:focus,
.e-button--success:hover,
.e-button--success:visited {
  background: var(--green-800);
}

.e-button--success {
  background: var(--green-700);
  border-color: var(--green-700);
  border-bottom-color: var(--green-800);
}

.e-button--success[disabled] {
  background: var(--green-400);
  border-color: var(--green-400);
  border-bottom-color: var(--green-700);
}
