.c-navigation {
  height: 60px;
  line-height: 18px;
}

.c-navigation > div > a > li,
.c-navigation > div > li {
  height: 100%;
}

.c-navigation > div > a > li:hover:not(.c-navigation__active),
.c-navigation > div > li:hover,
.c-navigation__active-hover {
  background-color: var(--grey-200);
  border-bottom: 3px solid var(--grey-300);
  color: var(--grey-700);
}

.c-navigation__active {
  color: var(--black) !important;
}

.c-navigation__item {
  padding-top: 22px;
}

.c-navigation__item-on-boarding-tooltip > div {
  top: 38px;
}

.c-navigation__item-on-boarding-tooltip a,
.c-navigation__item-on-boarding-tooltip a:visited {
  color: var(--white);
  font-weight: bold;
}
