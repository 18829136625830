/**
 * Relative sizing, naming in percentage
 *
 * Each variable, from "--rel-0" to "--rel-100", represents a different percentage of a parent container's size,
 * enabling relative sizing across your stylesheets.
 *
 * These variables can be used anywhere in our styles where a percentage might be needed,
 * providing a consistent method for defining relative sizes.
 */
:root {
  /* Represents 0% of the parent container's size */
  --rel-0: 0%;

  /* Represents 10% of the parent container's size */
  --rel-10: 10%;

  /* Represents 20% of the parent container's size */
  --rel-20: 20%;

  /* Represents 25% of the parent container's size */
  --rel-25: 25%;

  /* Represents 30% of the parent container's size */
  --rel-30: 30%;

  /* Represents roughly 33.33% of the parent container's size */
  --rel-33: 33.333333%;

  /* Represents 40% of the parent container's size */
  --rel-40: 40%;

  /* Represents 50% (half) of the parent container's size */
  --rel-50: 50%;

  /* Represents 60% of the parent container's size */
  --rel-60: 60%;

  /* Represents roughly 66.67% of the parent container's size */
  --rel-66: 66.666667%;

  /* Represents 70% of the parent container's size */
  --rel-70: 70%;

  /* Represents 75% of the parent container's size */
  --rel-75: 75%;

  /* Represents 80% of the parent container's size */
  --rel-80: 80%;

  /* Represents 90% of the parent container's size */
  --rel-90: 90%;

  /* Represents 95% of the parent container's size */
  --rel-95: 95%;

  /* Represents 100% (full) of the parent container's size */
  --rel-100: 100%;
}
