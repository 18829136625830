.c-widget-menu {
  left: 12px;
  top: 10px;
}

.c-widget-menu__img:hover,
.c-widget-menu__opened {
  background-color: var(--grey-200);
  border-radius: 12px;
}

.c-widget-menu__darker:hover,
.c-widget-menu__opened {
  background-color: var(--grey-300);
  border-radius: 12px;
}

.widget-menu-item {
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  background-color: white;
  font-size: 14px;
}

.widget-menu-item:hover {
  background-color: inherit;
}
