.singleDatePickerContainer {
  background: var(--white);
  box-shadow: var(--box-shadow-x);
  border-radius: var(--abs-2);
  display: flex;
}

.dayPickerWrapper {
  padding: var(--abs-24);
}

.dayPicker {
  background: var(--white);
}

.singleDatePickerContainer
  :global(
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(
        .DayPicker-Day--outside
      )
  ) {
  background: var(--transparent) !important;
  color: var(--white) !important;
}

.singleDatePickerContainer
  :global(
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(
        .DayPicker-Day--outside
      )
  )::before {
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--orange-600);
  position: absolute;
  z-index: -1;
}

.singleDatePickerContainer
  :global(
    .DatePicker.DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
        .DayPicker-Day--outside
      )
  ):hover:before {
  content: '';
  background-color: var(--orange-300) !important;
  width: 24px;
  height: 24px;
}

.dayPickerLabel {
  margin-bottom: var(--abs-16);
}

.datePickerInputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: var(--abs-8);
}

.datePickerInputWrapper .datePickerInput {
  background: var(--white);
  width: 134px;
  height: 36px;
}

.datePickerInputWrapper .disabledInput {
  background: var(--grey-300);
  color: var(--grey-600);
}

.singleDatePickerContainer :global(.DayPicker-Months) {
  border: none;
  border-right: 1px solid var(--grey-300);
}

.singleDatePickerContainer :global(.DayPicker-Caption > div)::after,
.singleDatePickerContainer :global(.DayPicker-Body)::after {
  border: none !important;
}

.singleDatePickerContainer :global(.DayPicker-Caption),
.singleDatePickerContainer :global(.DayPicker-Month):nth-child(1) {
  border: none !important;
  border-right: 1px solid var(--grey-300) !important;
}

.datePickerInputWrapper .invalidDate {
  border: 1px solid var(--red-700) !important;
  background-color: var(--red-300) !important;
}

.singleDatePickerContainer :global(.DayPicker-NavButton--prev),
.singleDatePickerContainer :global(.DayPicker-NavButton--next) {
  width: var(--abs-24);
  height: var(--abs-24);
  top: 3%;
}
