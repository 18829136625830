.c-notification-drawer {
  width: 349px;
  right: 0;
  z-index: 16;
  font-size: 12px;
}

.c-notification-drawer__notifications-title {
  font-size: 21px;
  line-height: 31px;
}

.c-notification-drawer__notifications {
  max-height: 70vh;
  height: 100vh;
  overflow-y: auto;
}

.c-notification-drawer__mark-all-read {
  font-size: 12px;
  top: 10px;
}

.c-notification-drawer__icons svg {
  top: 6px;
}

.c-snackbar-notification {
  min-height: 65px;
  font-size: 12px;
}

.c-snackbar-notification__close {
  height: 18px;
  width: 18px;
}

.c-notification-drawer__history {
  text-align: center;
  margin: 0 auto;
}

.c-notification-drawer__limit-notifications {
  text-align: center;
  margin: 0 auto;
  padding-top: 59px;
  padding-bottom: 59px;
}

.c-notification-drawer__no-results {
  text-align: center;
}
