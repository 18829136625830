.c-snackbar-notifications {
  position: absolute;
  right: 10px;
}

.c-snackbar-notifications__close {
  width: 154px;
  border-radius: 18px;
  background-color: #ffffffb3;
}

.c-snackbar-notifications__close span {
  position: relative;
  top: 3px;
}

.c-snackbar-notifications__close-icon {
  height: 18px;
  width: 18px;
  top: 4px;
}

.c-snackbar-notifications ul {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin: 0;
  list-style: none;
}

.c-snackbar-notifications li {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  right: -320px;
}

.c-snackbar-notifications__hidden {
  max-height: 0;
  overflow: hidden;
}

.c-snackbar-notifications__hidden-delete {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.c-snackbar-notifications__visible {
  max-height: 130px;
  transition: max-height 0.5s ease;
}

.c-snackbar-notifications__keep-place {
  right: 0px !important;
}

.c-snackbar-notifications__animate {
  animation-name: animateAnimation;
  animation-duration: 0.3s;
  right: 0px;
}

.c-snackbar-notifications__padding {
  padding-top: 40px;
}

@keyframes animateAnimation {
  0% {
    right: -320px;
  }
  100% {
    right: 0px;
  }
}
