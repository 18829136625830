.multi-level-menu {
  flex-direction: column;
  box-shadow: var(--box-shadow-45);
  width: 274px;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;
}

.multi-level-menu--item {
  width: min-content;
  padding: 10px 12px;
  justify-content: space-between;
  flex-direction: row;
  min-width: 250px;
  margin-bottom: 2px;
  min-height: 44px;
}

.multi-level-menu--item__icon {
  width: 24px;
  height: 24px;
}

.multi-level-menu--item:hover,
.multi-level-menu--item__active {
  background-color: var(--grey-200);
  border-radius: var(--border-radius);
}

.content-submenu > div > .multi-level-menu--item {
  padding: unset;
}
