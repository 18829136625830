.filterDropdown > ol {
  left: 0;
  box-shadow: unset;
  padding: 0;
}

.filterDropdownButton {
  color: var(--grey-700);
  border-color: var(--grey-300);
}

.filterDropdownButton.selected {
  background: var(--blue-200);
  border-color: var(--blue-300);
}

.filterDropdownButton.disabled {
  background: var(--white);
  color: var(--grey-300);
  border-color: var(--grey-300);
}
