.tableCell {
  overflow: visible;
  cursor: pointer !important;
}

.tableCell:hover,
.tableCell:has(.editAliasDialog) {
  z-index: var(--z-index-4);
  cursor: auto;
}

.tableCell:hover .editAliasLink {
  display: initial;
}

.cellWithAlias {
  display: inline-flex;
  gap: var(--abs-12);
  min-width: 210px;
  max-width: 360px;
  position: relative;
}

.alias {
  max-width: var(--abs-128);
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-600);
}

.editAliasLink {
  display: none;
}

.editAliasLink.disabled {
  cursor: not-allowed;
  color: var(--grey-600);
}

.editAliasDialog {
  z-index: var(--z-index-4);
  padding: var(--abs-12);
  background: var(--white);
  position: absolute;
  left: var(--rel-20);
  top: -150%;
  box-shadow: var(--box-shadow);
  border-radius: var(--abs-8);
}

.editAliasDialog .form {
  display: flex;
  align-items: flex-start;
  gap: var(--abs-8);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--abs-8);
}

.notes {
  display: flex;
  gap: var(--abs-8);
  justify-content: space-between;
}

.editAliasDialog input {
  margin: var(--abs-0);
  width: 300px;
}
