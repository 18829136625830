.c-notification-settings-modal * {
  font-size: 14px;
}

.c-notification-settings-modal td {
  width: 100%;
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}

.c-notification-settings-modal tr {
  border-top: 1px solid var(--grey-300) !important;
  border-bottom: 0;
}

.c-notification-settings-modal tr:hover {
  background-color: var(--white);
}

.c-notification-settings-modal td:first-child {
  padding-left: 0;
}
