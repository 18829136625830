.c-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.c-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.c-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey-500);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

.c-switch__slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.c-switch--medium {
  width: 44px;
  height: 24px;
}

.c-switch__slider--medium:before {
  height: 20px;
  width: 20px;
}

.c-switch--large {
  width: 48px;
  height: 28px;
}

.c-switch__slider--large:before {
  height: 24px;
  width: 24px;
}

input:checked + .c-switch__slider {
  background-color: var(--blue-700);
}

input:focus + .c-switch__slider {
  box-shadow: 0 0 1px var(--blue-700);
}

input:checked + .c-switch__slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.c-switch__slider.round {
  border-radius: 34px;
}

.c-switch__slider.round:before {
  border-radius: 50%;
}
