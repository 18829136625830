.showOnHover {
  visibility: hidden;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.contextGridRow:hover .showOnHover {
  visibility: visible;
}

.activeShowOnHover,
.contextGridRow:has(span.showMenuIcon):first-child span.showMenuIcon {
  visibility: visible;
}

.contextGridRow:has(span.activeShowOnHover),
.contextGridRow:has(span.showMenuIcon):first-child {
  background-color: var(--grey-200);
}

.showOnHover button {
  vertical-align: middle;
}
