.c-notification-drawer-notification__mark-read {
  display: none;
}

.c-notification-drawer-notification__time {
  display: block;
}

.c-notification-drawer-notification-notification__unread:hover
  .c-notification-drawer-notification__mark-read {
  display: block;
}

.c-notification-drawer-notification-notification__unread:hover
  .c-notification-drawer-notification__time {
  display: none;
}
