.c-widget__loading {
  padding-top: 11vh;
}

.c-widget {
  height: 318px;
}

.c-widget__shadow {
  box-shadow: var(--box-shadow-45);
}

.c-widget__shadow:hover {
  box-shadow: var(--box-shadow-45);
}

.c-widget-legend {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters-crossed-icon {
  margin-right: 4px;
  padding-top: 0px !important;
}

.filters-crossed-icon .tooltip-wrapper--bottom-trailing {
  right: -12px;
}

.filters-crossed-icon img {
  height: 24px;
}
