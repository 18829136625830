.e-button--primary:active,
.e-button--primary:focus,
.e-button--primary:hover,
.e-button--primary:visited {
  background: var(--blue-800);
}

.e-button--primary {
  background: var(--blue-700);
  border-color: var(--blue-700);
  border-bottom-color: var(--blue-800);
  color: var(--white);
}

.e-button--primary[disabled] {
  background: var(--blue-300);
  border-color: var(--blue-300);
  border-bottom-color: var(--blue-400);
}
