.schedules-and-availability {
  padding: 25px 25px 0 25px;
}

.schedules-and-availability .snackbar {
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 2px;
  background: var(--grey-200);
}

.schedules-and-availability .snackbar .arrow-icon {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.schedules-and-availability .snackbar .arrow-icon.inverted {
  transform: rotate(-90deg);
}

.schedules-and-availability .remaining-pushes-button {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.schedules-and-availability .remaining-pushes-list {
  border-radius: 2px;
  box-shadow: var(--box-shadow-45);
  background: white;
  position: absolute;
  top: 40px;
  right: -100px;
  min-width: 370px;
  min-height: 54px;
  padding: 0px 16px;
  cursor: default;
  max-height: 550px;
  overflow: auto;
  z-index: 1;
}

@media (max-width: 1330px) {
  .schedules-and-availability .remaining-pushes-list {
    right: -13px;
  }
}

.remaining-pushes-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.remaining-pushes-list::-webkit-scrollbar:hover {
  background-color: var(--grey-200);
}

.remaining-pushes-list::-webkit-scrollbar-thumb {
  border: 2px solid var(--transparent);
  background-clip: padding-box;
  -webkit-border-radius: 4px;
  background-color: var(--grey-300);
}

.remaining-pushes-list::-webkit-scrollbar-thumb:active {
  background-color: var(--grey-500);
}

.schedules-and-availability .remaining-pushes-list li {
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
}

.schedules-and-availability .remaining-pushes-list li p {
  color: var(--grey-600);
}

.schedules-and-availability .remaining-pushes-list li .remaining-pushes {
  color: var(--grey-700);
}

.schedules-and-availability .remaining-pushes-list li:not(:last-child) {
  border-bottom: solid 1px var(--grey-200);
}

.schedules-and-availability .remaining-pushes-list li .import-schedule {
  display: flex;
  gap: 4px;
  align-items: center;
}
