.banner-configurator {
  padding: var(--gutter--huge);
}

.banner-configurator .dropdown-container {
  display: flex;
}

.banner-configurator .dropdown-container .label {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.banner-configurator .dropdown-container .dropdown {
  min-width: 150px;
  padding-left: 15px;
}
