.stepperWrapper {
  display: flex;
  justify-content: flex-start;
  margin-left: 12%;
}

.stepperItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepperItem:first-child::after {
  position: absolute;
  content: '';
  border-bottom: var(--abs-2) solid var(--green-700);
  width: 224px;
  top: 18%;
  left: 57%;
  border-radius: var(--abs-2);
  margin-left: 8px;
}

.stepperItem:last-child {
  margin-left: 148px;
}

.stepperItem:first-child::before {
  content: none;
}

.stepperItem:last-child::after {
  content: none;
}

.icon {
  fill: var(--green-700);
}

.stepName {
  font-weight: var(--font-weight-700);
  margin-top: var(--gutter--medium);
}
