.c-reports-type-list-row:not(:last-child) {
  border-top: 1px solid var(--grey-200);
  border-bottom: 0 !important;
}

.c-reports-type-list-row:last-child {
  border-top: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);
}

.c-reports-type-list-row > td:last-child {
  text-align: right;
}

.c-reports-type-list-row > td:first-child {
  width: 400px;
}

.c-reports-type-list-row {
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.c-reports-type-list-row__active {
  vertical-align: 5px;
  font-size: 14px;
}

.c-reports-type-list-row__active-status {
  padding-right: 70px;
}

.c-reports-type-list-row__name {
  font-size: 16px;
}

.c-reports-type-list-row__generated,
.c-reports-type-list-row__open {
  font-size: 14px;
}

.c-reports-type-list td:first-child {
  padding-left: 16px;
}

.c-reports-type-list-row__select {
  border: 0px;
  outline: 0px;
  width: 170px;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
  height: 50px;
}

.c-reports-type-list td {
  padding-top: 8px;
  padding-bottom: 8px;
}

table > .c-reports-type-list:hover td > select,
.c-reports-type-list tr:hover select {
  background-color: var(--grey-200);
}

.c-reports-type-list-row__open-icon {
  top: 7px;
}

.c-reports-dropdown-placeholder {
  width: 170px;
}
