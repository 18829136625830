.e-button--tertiary:active:not([disabled]),
.e-button--tertiary:hover:not([disabled]),
.e-button--tertiary:visited:not([disabled]) {
  background: var(--grey-600);
  color: var(--white);
}

.e-button--tertiary,
.e-button--tertiary:focus {
  background: var(--white);
  border-color: var(--grey-600);
  color: var(--grey-700);
}

.e-button--tertiary[disabled] {
  background: var(--white);
  color: var(--grey-400);
  border-color: var(--grey-400);
}

.e-button--tertiary:active:not([disabled]) svg,
.e-button--tertiary:hover:not([disabled]) svg,
.e-button--tertiary:visited:not([disabled]) svg {
  color: var(--white);
}
