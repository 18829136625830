.c-calendar-selector {
  height: 36px;
  min-width: 270px;
}

.c-history-toolbar__datepicker-bottom {
  margin-top: 36px;
}

.c-history-toolbar__select {
  width: 200px;
  border-radius: 2px;
  margin: 0;
  font-size: 14px;
  height: 36px;
}

.c-dropdown-calendar {
  padding-top: 0px;
  padding-left: 0px;
}

.c-dropdown-calendar__text {
  padding-top: 7px;
  color: var(--grey-700);
}

.c-calendar-selector__opened {
  /*
    TODO colours var(--blue-300) and var(--blue-400) should be reviewed due to the default style for focus depends on the browser
    and in the OS. This means that this colour does not fit in all the browsers/OS we are mantaining. Also 
    this should be added to variables.
  */
  box-shadow: 0px 0px 0px 3px var(--blue-300);
  border: 2px solid var(--blue-400);
}
