.c-card__column--large {
  width: 417px;
}

.c-card__column--huge {
  width: 572px;
}

.c-card__column--medium {
  width: 198px;
}

.c-card__column {
  width: 15%;
}
