/**
 * Border Definitions
 *
 * This block defines CSS custom properties (variables) for standardized border styles and widths to be used throughout the application.
 * This ensures consistent borders and makes it easier to manage and update the border styles.
 *
 * Each border width is named with the format `--border-[width]`.
 *
 * You can customize the border color and style by setting `--border-color` and `--border-style` to your desired values.
 * By default, it uses the current font color and solid style.
 *
 * Examples:
 * To set a border with a custom color:
 *   .button {
 *       --border-color: var(--blue-500);
 *       border: var(--border-1);
 *   }
 *
 *
 * NOTE : I've used here the universal selector instead of relying on the :root to define the variables at all levels.
          This approach offers greater flexibility, allowing us to modify variables such as --border-color and --border-style
          within lower levels/ inner scopes. By defining --border-1 at the root level, we inherit --border-color from the same level,
          therefore, its value will always be currentColor because it won't be re-evaluated again. If we wanted to use :root,
          we would need to re-scope --border-1 whenever we wanted to use a different border color. In other words, variables defined
          in relation to other variables only consider variables at the same level or levels above them.
 */
* {
  /* Border Color */
  --border-color: currentColor;

  /* Border Style */
  --border-style: solid;

  /* Borders */
  --border-1: var(--abs-1) var(--border-style) var(--border-color);
  --border-2: var(--abs-2) var(--border-style) var(--border-color);
}
