.segmentedButtons {
  display: flex;
}

.button {
  z-index: 1;
  border-radius: unset;
}

.button.selected {
  z-index: 2;
}

.button:hover {
  z-index: 3;
}

.button.first {
  border-start-start-radius: var(--abs-2);
  border-end-start-radius: var(--abs-2);
}

.button.last {
  border-start-end-radius: var(--abs-2);
  border-end-end-radius: var(--abs-2);
}

.button:not(.first) {
  margin-inline-start: calc(-1 * var(--abs-1));
}
