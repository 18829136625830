/**
 * CSS Custom Properties for Transformation
 *
 * This block defines CSS custom properties (variables) for controlling various transformation parameters on an element.
 * These include translation (movement), rotation, skewing, and scaling in both the X and Y dimensions.
 *
 * The initial values are set to:
 * --translate-x, --translate-y: 0 (meaning no translation)
 * --rotate: 0 (meaning no rotation)
 * --skew-x, --skew-y: 0 (meaning no skewing)
 * --scale-x, --scale-y: 1 (meaning no scaling)
 *
 * Note: Each variable's name clearly indicates its purpose, and can be adjusted as needed in specific contexts.
 * For example, "--translate-x: 10px" moves an element 10 pixels to the right.
 */
:root {
  /* X-axis translation value. */
  --translate-x: 0;

  /* Y-axis translation value. */
  --translate-y: 0;

  /* Rotation value for the element. */
  --rotate: 0;

  /* Skew transformation along the X-axis. */
  --skew-x: 0;

  /* Skew transformation along the Y-axis.*/
  --skew-y: 0;

  /*  Scale transformation along the X-axis. Initial value is 1, representing no scaling. */
  --scale-x: 1;

  /* Scale transformation along the Y-axis. Initial value is 1, representing no scaling. */
  --scale-y: 1;
}
