.contentWrapper {
  margin-right: var(--gutter--small);
  flex-grow: 8;
  color: white;
  font-size: 14px;
}

.closeIcon {
  color: white;
  flex-grow: 8;
}
