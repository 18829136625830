.u-border-bottom--double {
  border-width: 0 0 2px 0;
}

.u-border-bottom--triple {
  border-width: 0 0 3px 0;
}

.u-border-bottom--juri-very-light {
  border-bottom: 1px solid var(--grey-300);
}

.u-border-top--juri-very-light {
  border-top: 1px solid var(--grey-300);
}

.u-border-vertical {
  border-width: 1px 0 1px 0;
}

.u-border--zero {
  border: 0;
}

.u-border--juri-lighter {
  border-top: 1px solid var(--grey-500);
}

.u-border--orange-lighter-1px {
  border: 1px solid var(--orange-400);
}

.u-border-bottom--green-lighter {
  border-bottom: 1px solid var(--green-400);
}

.u-border-bottom--red-lighter {
  border-bottom: 1px solid var(--red-500);
}

.u-border-bottom--orange-lighter {
  border-bottom: 1px solid var(--orange-400);
}

.u-border-bottom--blue-lighter {
  border-bottom: 1px solid var(--blue-400);
}

.u-border-top--blue-lighter {
  border-top: 1px solid var(--blue-400);
}

.u-border--blue-lighter {
  border: 1px solid var(--blue-400);
}
