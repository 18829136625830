/*
 * ## Tooltip's outer wrapper to to grant the `.tooltip` a `max-width` which could
 * exceed its parent within a `position: absolute;` context without using a fixed width.
 *
 * The tooltip is positioned via this wrapper's modifier classes.
 */

.tooltip-wrapper {
  position: absolute;
  width: 267px;
  z-index: 100;
}

.tooltip {
  /* stylelint-disable sh-waqar/declaration-use-variable */
  background-color: var(--grey-800);
  /* stylelint-enable */
  border-radius: 2px;
  bottom: auto;
  display: none;
  left: auto;
  line-height: 18px;
  max-width: 267px;
  padding: 8px;
  position: relative;
  width: auto;
  pointer-events: none;
  /*
     * ## Tooltip's arrow
     */
}

.tooltip__body {
  padding: 8px;
}

.tooltip:before {
  border-top: 8px solid var(--grey-800);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -8px;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

/*
 * ## Tooltip's parent. Show the `.tooltip` on :hover. Use this class for toggling the tooltip's visibility
 */

.has-tooltip {
  position: relative;
}

.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip,
.has-tooltip:active .tooltip,
.has-tooltip.active .tooltip {
  display: inline-block;
}

/*
 * # Tooltip on the bottom center side of the parent element.
 */

.tooltip-wrapper--bottom-center {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-wrapper--bottom-center .tooltip {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-wrapper--bottom-center .tooltip:before {
  left: 50%;
  transform: rotate(180deg) translateX(50%);
}

/*
 * # Tooltip on the bottom left side of the parent element.
 */

.tooltip-wrapper--bottom-leading {
  left: 0;
}

.tooltip-wrapper--bottom-leading .tooltip {
  float: left;
}

.tooltip-wrapper--bottom-leading .tooltip:before {
  left: 16px;
}

/*
 * # Tooltip on the bottom right side of the parent element.
 */

.tooltip-wrapper--bottom-trailing {
  right: 0;
}

.tooltip-wrapper--bottom-trailing .tooltip {
  float: right;
}

.tooltip-wrapper--bottom-trailing .tooltip:before {
  right: 16px;
}

/*
 * # Tooltip on the left bottom side of the parent element.
 */

.tooltip-wrapper--leading-bottom {
  bottom: 0;
}

.tooltip-wrapper--leading-bottom .tooltip:before {
  bottom: 16px;
}

/*
 * # Tooltip on the left center side of the parent element.
 */

.tooltip-wrapper--leading-center {
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-wrapper--leading-center .tooltip:before {
  top: 50%;
  transform: rotate(-90deg) translateX(25%);
}

/*
 * # Tooltip on the left top side of the parent element.
 */

.tooltip-wrapper--leading-top {
  top: 0;
}

.tooltip-wrapper--leading-top .tooltip:before {
  top: 16px;
}

/*
 * # Tooltip Placeholders
 */

.tooltip-wrapper--top-center,
.tooltip-wrapper--top-leading,
.tooltip-wrapper--top-trailing {
  bottom: 100%;
  margin-bottom: 4px;
}

.tooltip-wrapper--top-center .tooltip:before,
.tooltip-wrapper--top-leading .tooltip:before,
.tooltip-wrapper--top-trailing .tooltip:before {
  bottom: -8px;
}

.tooltip-wrapper--trailing-bottom,
.tooltip-wrapper--trailing-center,
.tooltip-wrapper--trailing-top {
  left: 100%;
  margin-left: 4px;
}

.tooltip-wrapper--trailing-bottom .tooltip,
.tooltip-wrapper--trailing-center .tooltip,
.tooltip-wrapper--trailing-top .tooltip {
  float: left;
}

.tooltip-wrapper--trailing-bottom .tooltip:before,
.tooltip-wrapper--trailing-center .tooltip:before,
.tooltip-wrapper--trailing-top .tooltip:before {
  left: -12px;
  right: auto;
  transform: rotate(90deg);
}

.tooltip-wrapper--bottom-center,
.tooltip-wrapper--bottom-leading,
.tooltip-wrapper--bottom-trailing {
  top: 100%;
  margin-top: 4px;
}

.tooltip-wrapper--bottom-center .tooltip:before,
.tooltip-wrapper--bottom-leading .tooltip:before,
.tooltip-wrapper--bottom-trailing .tooltip:before {
  bottom: auto;
  top: -8px;
  transform: rotate(180deg);
}

.tooltip-wrapper--leading-bottom,
.tooltip-wrapper--leading-center,
.tooltip-wrapper--leading-top {
  right: 100%;
  margin-right: 4px;
}

.tooltip-wrapper--leading-bottom .tooltip,
.tooltip-wrapper--leading-center .tooltip,
.tooltip-wrapper--leading-top .tooltip {
  float: right;
}

.tooltip-wrapper--leading-bottom .tooltip:before,
.tooltip-wrapper--leading-center .tooltip:before,
.tooltip-wrapper--leading-top .tooltip:before {
  right: -12px;
  left: auto;
  transform: rotate(-90deg);
}

/*
 * # Tooltip on the top center side of the parent element.
 */

.tooltip-wrapper--top-center {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-wrapper--top-center .tooltip {
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-wrapper--top-center .tooltip:before {
  left: 50%;
  transform: translateX(-50%);
}

/*
 * # Tooltip on the top left side of the parent element.
 */

.tooltip-wrapper--top-leading {
  left: 0;
}

.tooltip-wrapper--top-leading .tooltip {
  float: left;
  top: -11px;
  left: -4%;
}

.tooltip-wrapper--top-leading .tooltip:before {
  left: calc(50% - 8px);
}

/*
 * # Tooltip on the top right side of the parent element.
 */

.tooltip-wrapper--top-trailing {
  right: 0;
}

.tooltip-wrapper--top-trailing .tooltip {
  float: right;
}

.tooltip-wrapper--top-trailing .tooltip:before {
  right: 16px;
}

/*
 * # Tooltip on the right bottom side of the parent element.
 */

.tooltip-wrapper--trailing-bottom {
  bottom: 0;
}

.tooltip-wrapper--trailing-bottom .tooltip:before {
  bottom: 16px;
}

/*
 * # Tooltip on the right center side of the parent element.
 */

.tooltip-wrapper--trailing-center {
  top: 50%;
  transform: translateY(-50%);
}

.tooltip-wrapper--trailing-center .tooltip:before {
  top: 50%;
  transform: rotate(90deg) translateX(-25%);
}

/*
 * # Tooltip on the right top side of the parent element.
 */

.tooltip-wrapper--trailing-top {
  top: -8px;
}

.tooltip-wrapper--trailing-top .tooltip:before {
  top: 16px;
}
