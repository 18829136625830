.modalDialog {
  opacity: 0;
  transition: all var(--duration-20) allow-discrete;
  background: var(--white);
  border: none;
  box-shadow: var(--box-shadow-15);
  border-radius: var(--abs-2);
  margin: auto;
}

.modalDialog[open] {
  opacity: 1;
}

@starting-style {
  .modalDialog[open] {
    opacity: 0;
  }
}

.modalDialog::backdrop {
  background-color: var(--transparent);
  transition: all var(--duration-20) allow-discrete;
}

.modalDialog[open]::backdrop {
  background-color: var(--black-a75);
}

.header {
  padding: var(--abs-24);
  padding-block-end: var(--abs-0);
  display: flex;
  gap: var(--abs-8);
}

.header h2 {
  font-size: var(--abs-16);
  flex: 1;
}

.content,
.footer {
  padding: var(--abs-24);
}

.footer {
  display: flex;
  gap: var(--abs-12);
  --border-color: var(--grey-300);
  border-block-start: var(--border-1);
}

.footerEnd {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: var(--abs-12);
}

.noPadding {
  padding: var(--abs-0);
}
