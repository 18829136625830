.tag {
  --border-radius: 18px;
  display: flex;
  height: var(--abs-36);
  align-items: center;
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-150);
  padding: var(--abs-0) var(--abs-12);
  gap: var(--abs-8);
  border-radius: var(--border-radius);
  width: fit-content;
  background-color: var(--white);
  border-width: var(--abs-1);
  border-style: solid;
  border-color: var(--grey-400);
  color: inherit;
  text-wrap: nowrap;
}

.tag:hover {
  border-color: var(--grey-700);
}

.selected {
  background-color: var(--blue-200);
  border-color: var(--blue-300);
}

.selected:hover,
.selected:focus {
  border-color: var(--blue-500);
}
.closeIcon {
  color: var(--grey-700);
  cursor: pointer;
}

.closeIcon:hover {
  color: var(--grey-800);
}

.disabled,
.disabled:hover {
  border-color: var(--grey-400);
  color: var(--grey-400);
}

.disabled .closeIcon {
  color: var(--grey-700);
}

.disabled .closeIcon:hover {
  color: var(--grey-800);
}
.tag.doubleActionTag {
  padding: var(--abs-0);
  gap: var(--abs-0);
}

.doubleActionTag > button {
  border: none;
  background: none;
  height: 100%;
  display: flex;
  align-items: center;
  gap: var(--abs-8);
  color: inherit;
}

.doubleActionTag > button:hover {
  background-color: var(--grey-200);
}

.doubleActionTag > button:first-child {
  padding-inline-start: var(--abs-12);
  padding-inline-end: var(--abs-0);
  border-start-start-radius: var(--border-radius);
  border-end-start-radius: var(--border-radius);
}

.doubleActionTag > button:first-child::after {
  content: '';
  border-left: var(--abs-1) solid var(--grey-400);
  height: var(--abs-24);
}

.doubleActionTag > button:last-child {
  padding-inline-end: var(--abs-12);
  padding-inline-start: var(--abs-8);
  border-start-end-radius: var(--border-radius);
  border-end-end-radius: var(--border-radius);
}
.doubleActionTag > button:last-child svg:active {
  transform: rotate(180deg);
  animation: transform var(--duration-20);
}
