.compareDatePickerSidebar {
  --compare-sidebar-width: 470px;
  width: var(--compare-sidebar-width);
  border-left: var(--abs-1) solid var(--grey-300);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--gutter--large);
}
.periodSelectorWrapper {
  display: flex;
  align-items: center;
  height: var(--abs-36);
  gap: var(--abs-8);
}
.periodSelectorWrapper * {
  height: var(--rel-100) !important;
}
.periodSelectorWrapper select {
  margin: var(--abs-0);
  --select-width: 154px;
  width: var(--select-width);
}
.periodSelectorWrapper select.disabled {
  background-color: var(--grey-200);
  cursor: default !important;
}
.datePeriodMarker {
  width: var(--abs-2);
  border-radius: 3px;
  padding-left: var(--abs-2);
  background: var(--blue-700);
}
.datePickerInput {
  margin: var(--abs-0) !important;
}
.datePickerInput.disabled {
  background-color: var(--grey-200);
}
.datePickerInput.invalid {
  --border-color: var(--red-700);
  border: var(--border-1) !important;
  background-color: var(--red-400) !important;
}
.datePickerInput:focus {
  box-shadow: 0px 0px 0px 3px var(--blue-300);
  border-color: var(--blue-400);
  border: var(--border-2) !important;
}
.comparePeriodsContainer {
  --max-compare-periods-height: 180px;
  width: var(--rel-100);
  max-height: var(--max-compare-periods-height);
  overflow-y: scroll;
}
.comparePeriodsContainer::-webkit-scrollbar {
  display: none;
}
.datePeriodContainer {
  display: flex;
}

.removeDateRangeIcon {
  display: flex;
  align-items: center;
  height: var(--abs-36);
  margin-left: auto;
  margin-top: var(--gutter--tiny);
  cursor: pointer;
}

.compareSwitchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--abs-16) var(--abs-0);
  padding: var(--abs-8);
  background-color: var(--grey-100);
  border-radius: var(--abs-8);
}
.compareSwitchTooltip {
  display: flex;
}
.periodRangesActions {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--abs-8);
  margin-bottom: var(--abs-16);
}
.periodRangesActions :last-child {
  margin-left: auto;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--abs-12);
  padding: var(--gutter--large);
  border-top: 1px solid var(--grey-300);
}
