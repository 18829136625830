.multi-folder-select-active,
.multi-folder-select-item:hover * {
  background-color: var(--grey-200);
  color: var(--grey-700);
  border-radius: var(--border-radius);
}

.multi-folder-select-item {
  cursor: pointer;
}

.multi-folder-select > div {
  height: 36px;
}

.multi-folder-select-menu {
  position: absolute;
  z-index: 1;
  width: fit-content;
  background-color: white;
  left: 21%;
}

.multi-folder-select--list li {
  display: flex;
  align-items: center;
  height: 54px;
}

.multi-folder-select--list li:hover {
  border: 1px solid var(--blue-500);
  border-radius: var(--border-radius);
}

li.multi-folder-select--metric {
  height: 36px;
}

.multi-folder-select--list li > div,
.multi-folder-select--list li > svg {
  cursor: pointer;
}

.multi-folder-select div.disable-menu:hover {
  background: var(--grey-200);
  cursor: not-allowed;
}

.multi-folder-select div.multi-folder-select--tooltip {
  margin-left: 24px;
}

.time-period-onboarding-tooltip {
  position: relative;
  top: -50px;
}
