.navigationTab {
  position: relative;
  bottom: 5px;
  left: 0px;
}

.gridHeader.top {
  position: relative;
  bottom: 29px;
  left: 10px;
}

.gridHeader.bottom {
  position: relative;
  bottom: -5px;
  left: -20px;
}
