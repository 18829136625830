.c-filter-set-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  height: 36px;
  padding: var(--gutter--medium);
  margin-top: 1px;
  margin-bottom: 0;
  cursor: pointer;
  border-right: 4px solid transparent;
}

.c-filter-set-option.selected {
  background-color: var(--grey-200);
  border-right: 4px solid var(--grey-600);
}

.c-filter-set-option__label {
  font-weight: bold;
  line-height: var(--font-size--h-tiny);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
