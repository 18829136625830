/**
 * Color Definitions
 *
 * This block defines CSS custom properties (variables) for a standardized color palette to be used throughout the application.
 * This ensures consistent colors and makes it easier to manage and update the color scheme.
 *
 * Each color has multiple shades, defined as 100 (lightest) through 800 (darkest).
 * This provides flexibility in design while maintaining color coherence.
 *
 * Each color variable is named with the format `--[color]-[shade]`.
 *
 * Note: If a particular shade for a color isn't defined, it is recommended to use the nearest available shade.
 */
:root {
  /* Color: transparent */
  --transparent: transparent;

  /* Color: black */
  --black: #000;

  /* Color: white */
  --white: #fff;

  /* Alpha values for white */
  --white-a15: rgba(255, 255, 255, var(--alpha-15));
  --white-a25: rgba(255, 255, 255, var(--alpha-25));
  --white-a45: rgba(255, 255, 255, var(--alpha-45));

  /* Alpha values for grey-800 */
  --grey-a15: rgba(10, 17, 33, var(--alpha-15));
  --grey-a25: rgba(10, 17, 33, var(--alpha-25));
  --grey-a45: rgba(10, 17, 33, var(--alpha-45));

  --black-a75: rgb(0 0 0 / 75%);

  /* Color: blue */
  --blue-100: #f5fbff;
  --blue-200: #e5f5ff;
  --blue-300: #bde7ff;
  --blue-400: #7ed0ff;
  --blue-500: #4dbeff;
  --blue-600: #24a3ec;
  --blue-700: #0079c2;
  --blue-800: #00578b;

  /* Color: orange */
  --orange-100: #fff8f0;
  --orange-200: #ffead6;
  --orange-300: #ffcc9d;
  --orange-400: #ffb773;
  --orange-500: #ffa44d;
  --orange-600: #ff9128;
  --orange-700: #e27423;
  --orange-800: #b85900;

  /* Color: red */
  --red-100: #fdf7f8;
  --red-200: #faebed;
  --red-300: #f9cdd8;
  --red-400: #f5b1c1;
  --red-500: #f08ea4;
  --red-600: #e95e7d;
  --red-700: #e22851;
  --red-800: #b4183a;

  /* Color: green */
  --green-100: #ebfdec;
  --green-200: #d7fdd8;
  --green-300: #b0f1b2;
  --green-400: #8de28f;
  --green-500: #6dce70;
  --green-600: #25a82a;
  --green-700: #0a850e;
  --green-800: #075f0a;

  /* Color: grey */
  --grey-100: #fafafa;
  --grey-200: #f2f2f1;
  --grey-300: #d9d8d6;
  --grey-400: #bbbbb9;
  --grey-500: #8d8d8b;
  --grey-600: #6c6c6b;
  --grey-700: #4d4d4c;
  --grey-800: #171717;
}
