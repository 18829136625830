.simple-snackbar {
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  padding: 10px 12px;
  gap: 12px;
  max-width: 450px;
  min-width: 250px;
  color: var(--grey-700);
  font-size: 14px;
  line-height: 21px;
  border-width: 1px;
  border-style: solid;
}

.simple-snackbar > p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.simple-snackbar--success {
  background: var(--green-100);
  border-color: var(--green-300);
}

.simple-snackbar--error {
  background: var(--red-200);
  border-color: var(--red-400);
}

.simple-snackbar--warning {
  background: var(--orange-200);
  border-color: var(--orange-300);
}

.simple-snackbar--info {
  background: white;
  border-color: var(--grey-300);
}
