.filterFormField {
  display: flex;
  gap: var(--abs-24);
  align-items: center;
}

.nameAndQualifier {
  font-size: var(--font-size-10);
}

.filterName,
.inputSeparator {
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-150);
  font-weight: var(--font-weight-700);
}

.inputs {
  flex: 2;
  display: flex;
  align-items: center;
  gap: var(--abs-8);
}
