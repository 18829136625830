/**
 * CSS Variables for animation durations.
 *
 * @description
 * Here we declare CSS variables for animation durations to use throughout our styles.
 * We can use these variables to standardize timing of animations across all elements.
 * They are used to create a uniform look and feel, increase maintainability, and reduce redundancy.
 */
:root {
  /* Represents an animation duration of 0.1s. */
  --duration-10: 0.1s;

  /* Represents an animation duration of 0.15s. */
  --duration-15: 0.15s;

  /* Represents an animation duration of 0.2s. */
  --duration-20: 0.2s;

  /* Represents an animation duration of 0.5. */
  --duration-50: 0.5s;

  /* Represents an animation duration of 1s. */
  --duration-100: 1s;

  /* Represents an animation duration of 2s. */
  --duration-200: 2s;

  /* Represents an animation duration of 4s. */
  --duration-400: 4s;
}
