/* TODO: replace with a specific variant of button component */
.button {
  background-color: var(--blue-700);
  color: var(--white);
}

.button svg {
  color: var(--white);
}

.withoutValidationModalCTAButton {
  background-color: var(--grey-600) !important;
  border-color: var(--grey-600);
}

.withoutValidationModalCTAButton:hover {
  background-color: var(--grey-700) !important;
  border-color: var(--grey-700);
}

.withoutValidationModalText {
  margin-top: var(--abs-12);
}
