.inventoryFileUploadModal {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.inventoryFileUploadModalHeader {
  padding: var(--gutter--large) var(--gutter--large) 0 var(--gutter--large);
}

.cta {
  display: flex;
  flex-direction: row-reverse;
  gap: var(--horizontal-padding, 12px);
  padding: var(--gutter--large);
}

.borderBottom {
  border-bottom: 1px solid var(--grey-200);
}

.inventoryFileUploadModalBody {
  margin: var(--gutter--large);
}

.withoutHeaderInventoryFileUploadModalBody {
  margin: 48px var(--gutter--large) var(--gutter--large);
}

.modalSize {
  width: 600px;
}
