.c-dimension-value-dropdown {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  background: var(--white);
  box-shadow: var(--box-shadow-45);
  width: 420px;
}

.c-dimension-value-dropdown h1 {
  margin-bottom: var(--gutter--medium);
}

.c-dimension-value-dropdown-clear-btn {
  color: var(--blue-700);
  background-color: transparent;
  border: 0;
  font-weight: normal;
  padding: 0;
  float: right;
  text-align: right;
  margin-top: var(--gutter--medium);
  margin-bottom: var(--gutter--medium);
  font-size: var(--font-size--h-tiny);
}

.c-dimension-value-dropdown-cta {
  border-top: 1px solid var(--grey-200);
  padding: var(--gutter--large);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.c-dimension-value-dropdown-cta button {
  width: 50% !important;
  justify-content: space-around;
}

.c-dimension-value-dropdown-cta p {
  width: 50%;
  color: var(--grey-600);
  font-size: var(--font-size--h-tiny);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-dimension-value-dropdown-list {
  margin-top: var(--gutter--small);
  padding-left: var(--gutter--medium);
  padding-right: var(--gutter--medium);
}

.c-dimension-value-dropdown-list li:last-child {
  margin-bottom: 0;
}

.c-dimension-value-dropdown-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
  margin-bottom: var(--gutter--minuscule);
  padding: var(--gutter--tiny) var(--gutter--medium);
  background: var(--blue-200);
  color: var(--grey-700);
  font-size: var(--font-size--h-tiny);
  border: 1px solid var(--blue-300);
  border-radius: var(--border-radius);
}

.c-dimension-value-dropdown-list li:hover {
  border: solid 1px var(--blue-500);
}

.c-dimension-value-dropdown-list li > svg {
  cursor: pointer;
}

.c-dimension-value-dropdown-list li > div {
  display: flex;
  flex-direction: column;
}
