.c-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1001;
}

.c-modal__container {
  border-radius: 2px;
  box-shadow: var(--box-shadow-45);
  background-color: var(--white);
}

.c-modal__backdrop {
  background: var(--grey-800);
  z-index: 1000;
  opacity: 0.75;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.c-modal__container--small {
  width: 500px;
}

.c-modal__container--medium {
  width: 630px;
}

.c-modal__container--large {
  width: 800px;
}
