.c-chart-builder {
  min-height: calc(100vh - 205px);
}

.c-save-as-name-input {
  font-size: 14px !important;
}

.c-chart-builder svg.close-icon:hover {
  cursor: pointer;
  background-color: var(--grey-200);
  border-radius: 12px;
}

.c-red-tertiary-button {
  border-color: var(--red-800);
  color: var(--red-800);
}

.c-red-tertiary-button:hover {
  background: var(--red-800) !important;
  color: white !important;
}

.c-min-width-500 {
  min-width: 500px;
}

.hide-long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
  display: inline-block;
}

.hide-long-text--tooltip {
  width: 100%;
  padding-top: 0 !important;
}

.hide-long-text--tooltip__position {
  margin-right: 6%;
}

.c-chart-builder--title {
  white-space: nowrap;
  width: 80%;
}

.chart-builder--no-widget {
  top: 40%;
}

.chart-builder--no-widget div {
  color: var(--grey-600);
}
.chartBuilderPeriodPickerDisabled {
  pointer-events: none;
  opacity: 0.5;
}
