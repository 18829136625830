.c-grid-footer tr:first-child td {
  border-top: 1px solid var(--grey-300);
}

.c-grid-footer tr:not(last-child) td {
  border-bottom: 1px solid var(--grey-200);
}

.c-grid-footer tr:last-child td {
  border-top: 1px solid var(--grey-300);
  border-bottom: 1px solid var(--grey-300);
}

.c-grid-footer td {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  bottom: 0;
  color: black;
  min-width: 20px;
  /* TODO define it as a constant */
  background-color: white;
  padding-block: var(--abs-0);
  vertical-align: middle;
  height: var(--abs-36);
}

.c-grid-footer__sticky {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  background: white;
  left: 0;
  z-index: var(--z-index-1);
}

.c-grid-footer__border-sticky {
  border-right: 1px solid var(--grey-300);
}

.c-grid-footer__column-aligned-right {
  text-align: right;
}

.c-grid-footer tr {
  height: var(--abs-36);
  vertical-align: middle;
}
