.c-bidding {
  margin: var(--gutter) auto 7vw;
  max-width: 1400px;
  min-height: 70vh;
}

@media screen and (max-width: 1920px) {
  .c-bidding {
    max-width: 1200px;
  }
}

.c-bidding__loading_wrapper {
  min-height: 70vh;
}
