.c-composedInlineDropdown {
  min-width: 186px;
}

.c-composedInlineDropdown p {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-composedInlineDropdown__arrow-icon {
  margin-left: 4px;
  transform: rotate(90deg);
  transition: 0.2s;
  width: 18px;
  height: 18px;
}

.c-composedInlineDropdown__arrow-icon-aligned-right {
  margin-left: 4px;
  transform: rotate(90deg);
  transition: 0.2s;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 7px;
  top: 6px;
}

.c-composedInlineDropdown__arrow-icon--upside-down {
  transform: rotate(-90deg);
}
