/**
 * CSS Variables for opacity levels.
 *
 * @description
 * Here we declare CSS variables for opacity levels to use throughout our styles.
 * We can use these variables to standardize opacity across all elements.
 * They are used to create a uniform look and feel, increase maintainability and reduce redundancy.
 */
:root {
  /* Represents an opacity level of 0%, fully transparent. */
  --alpha-0: 0;

  /* Represents an opacity level of 10%. */
  --alpha-10: 0.1;

  /* Represents an opacity level of 15%. */
  --alpha-15: 0.15;

  /* Represents an opacity level of 25%. */
  --alpha-25: 0.25;

  /* Represents an opacity level of 30%. */
  --alpha-30: 0.3;

  /* Represents an opacity level of 45%. */
  --alpha-45: 0.45;

  /* Represents an opacity level of 50%, semi-transparent. */
  --alpha-50: 0.5;

  /* Represents an opacity level of 75%. */
  --alpha-75: 0.75;

  /* Represents an opacity level of 90%. */
  --alpha-90: 0.9;

  /* Represents an opacity level of 100%, fully opaque. */
  --alpha-100: 1;
}
