.tableRow:hover {
  background-color: var(--white);
}

.tableCell {
  font-size: var(--font-size--h-tiny);
  padding: var(--gutter);
  padding-inline-end: 0;
}

.cellWithButton {
  display: flex;
  justify-content: flex-end;
}

.downloadIcon {
  margin-inline-end: var(--gutter--medium);
}

.artifactKey {
  font-weight: bold;
  margin-block-end: var(--gutter--t-small);
}

.timestamp {
  color: var(--grey-500);
}
