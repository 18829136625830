.historyEventFiles {
  width: 168px;
  border-inline-start: solid 1px var(--grey-300);
  padding-inline-start: var(--gutter--large);
  display: flex;
  align-items: center;
}

.downloadMenu {
  min-width: var(--abs-192);
}
