.c-list-header th {
  position: -webkit-sticky;
  top: 0;
  color: var(--grey-500);
  min-width: 20px;
  background-color: var(--white);
  z-index: 2;
  text-overflow: clip;
  border-left-width: 0;
  border-right-width: 0;
}

.c-list-header th:first-child {
  border-left-width: 1px;
  text-align: left;
  padding-left: var(--gutter);
}

.c-list-header th:last-child {
  border-right-width: 1px;
  text-align: right;
  padding-right: var(--gutter);
}

.c-list-header tr {
  border-left-width: 1px;
  border-right-width: 1px;
}

.c-list-header th:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.c-list-header th:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.c-list-row {
  height: 50px;
  line-height: 50px;
}

.c-list-row:not(:last-child) {
  border-top: 1px solid var(--grey-200);
  border-bottom: 0 !important;
}

.c-list-row > td:last-child {
  text-align: right;
}
