.c-dashboard-dropdown {
  top: 7px;
}

.c-dashboard-dropdown__input {
  width: 288px;
}

.c-dashboard-dropdown__menu {
  padding: 0 var(--gutter--medium);
}

.c-dashboard-dropdown__menu-header {
  padding-top: var(--gutter--medium);
  padding-left: var(--gutter--medium);
  padding-right: var(--gutter--medium);
  padding-bottom: 0;
}

.c-dashboard-dropdown__menu-footer {
  padding-left: var(--gutter--medium);
  padding-right: var(--gutter--medium);
  padding-bottom: var(--gutter--medium) !important;
  cursor: pointer;
  display: flex;
  min-height: 48px;
  line-height: 48px;
  font-size: var(--font-size--h-tiny);
}

.c-dashboard-dropdown__menu li {
  border-bottom: 1px solid var(--grey-200);
}

.c-dashboard-dropdown__popover {
  right: 12px;
}

.c-dashboard-dropdown__on-boarding {
  bottom: 44px;
  left: 6px;
}
