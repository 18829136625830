.icon {
  --rotation: 0deg;
  transform: rotate(var(--rotation));
  transition: transform var(--duration-20);
}

.rotation-90 {
  --rotation: 90deg;
}

.rotation-180 {
  --rotation: 180deg;
}

.rotation-270 {
  --rotation: 270deg;
}
