/**
 * Font Sizing in REM Units, Named by Pixel Equivalents
 */
:root {
  /* Font size: 1rem (10px) */
  --font-size-10: 1rem;

  /* Font size: 1.2rem (12px) */
  --font-size-12: 1.2rem;

  /* Font size: 1.4rem (14px) */
  --font-size-14: 1.4rem;

  /* Font size: 1.6rem (16px) */
  --font-size-16: 1.6rem;

  /* Font size: 1.8rem (18px) */
  --font-size-18: 1.8rem;

  /* Font size: 2rem (20px) */
  --font-size-20: 2rem;

  /* Font size: 2.4rem (24px) */
  --font-size-24: 2.4rem;

  /* Font size: 2.8rem (28px) */
  --font-size-28: 2.8rem;

  /* Font size: 3.2rem (32px) */
  --font-size-32: 3.2rem;

  /* Font size: 4.4rem (44px) */
  --font-size-44: 4.4rem;
}
