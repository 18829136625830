/* Argument type is used here to increase specificity,
   because there is a global rule that targets all input elements.
*/
.input,
.input[type] {
  margin: var(--abs-0);
  height: var(--abs-36);
  border: var(--abs-1) solid var(--grey-400);
}

.input:focus,
.input[type]:focus {
  border-color: var(--blue-400);
}
.decoration {
  color: var(--grey-700);
  font-weight: var(--font-weight-700);
  position: relative;
  margin-left: -45px;
  width: var(--abs-24);
  height: var(--abs-24);
  font-size: var(--font-size-14);
  display: flex;
  align-items: center;
  padding-left: var(--abs-8);
  padding-right: var(--abs-8);
}
