.c-composedDropdown {
  min-width: 186px;
  height: 58px;
  padding: var(--gutter--medium) var(--gutter);
}

.c-composedDropdown__selectable:hover {
  background-color: var(--grey-200);
}

.c-composedDropdown__subTitle {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.composedDropdown__subTitle__icon {
  margin-right: var(--gutter--medium);
  width: 28px;
  height: 18px;
}

svg.composedDropdown__subTitle__icon {
  width: 24px;
  height: 24px;
}

.c-composedDropdown__arrow-icon {
  transform: rotate(90deg);
  transition: 0.2s;
  width: 18px;
  height: 18px;
  top: 2px;
}

.c-composedDropdown__arrow-icon--upside-down {
  transform: rotate(-90deg);
}
