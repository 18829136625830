/**
 * Absolute Sizing in REM Units, Named by Pixel Equivalents
 *
 * This block defines CSS custom properties (variables) for standardizing sizes and spacings across the application.
 * The sizes are named according to their pixel equivalents at default browser settings (1rem = 16px) for easy understanding.
 * However, the actual values are specified in 'rem' units to provide scalability and maintain accessibility.
 *
 * Each size/spacing variable is named with the format `--abs-[pixel equivalent]`.
 *
 * Note: Variable `--abs-0` denotes no size or spacing, while `--abs-1` provides minimal size or spacing equivalent to 1px.
 * These two variables are not specified in 'rem' units.
 */
:root {
  /* Absolute size/spacing: 0 (0px) - No size or spacing */
  --abs-0: 0;

  /* Absolute size/spacing: 1px (1px) - Minimal size or spacing, equivalent to 1px */
  --abs-1: 1px;

  /**
     * Sizes or spacings below are defined in 'rem' units for scalability and accessibility,
     * but named by their pixel equivalents at default browser settings for easy reference
     */

  /* Absolute size/spacing: 0.2rem (2px) */
  --abs-2: 0.2rem;

  /* Absolute size/spacing: 0.4rem (4px) */
  --abs-4: 0.4rem;

  /* Absolute size/spacing: 0.8rem (8px) */
  --abs-8: 0.8rem;

  /* Absolute size/spacing: 1.2rem (12px) */
  --abs-12: 1.2rem;

  /* Absolute size/spacing: 1.6rem (16px) */
  --abs-16: 1.6rem;

  /* Absolute size/spacing: 2rem (20px) */
  --abs-20: 2rem;

  /* Absolute size/spacing: 2.4rem (24px) */
  --abs-24: 2.4rem;

  /* Absolute size/spacing: 2.8rem (28px) */
  --abs-28: 2.8rem;

  /* Absolute size/spacing: 3.2rem (32px) */
  --abs-32: 3.2rem;

  /* Absolute size/spacing: 3.6rem (36px) */
  --abs-36: 3.6rem;

  /* Absolute size/spacing: 4rem (40px) */
  --abs-40: 4rem;

  /* Absolute size/spacing: 4.4rem (44px) */
  --abs-44: 4.4rem;

  /* Absolute size/spacing: 4.8rem (48px) */
  --abs-48: 4.8rem;

  /* Absolute size/spacing: 5.2rem (52px) */
  --abs-52: 5.2rem;

  /* Absolute size/spacing: 5.6rem (56px) */
  --abs-56: 5.6rem;

  /* Absolute size/spacing: 6rem (60px) */
  --abs-60: 6rem;

  /* Absolute size/spacing: 6.4rem (64px) */
  --abs-64: 6.4rem;

  /* Absolute size/spacing: 8rem (80px) */
  --abs-80: 8rem;

  /* Absolute size/spacing: 10rem (100px) */
  --abs-100: 10rem;

  /* Absolute size/spacing: 11.6rem (116px) */
  --abs-116: 11.6rem;

  /* Absolute size/spacing: 12.8rem (128px) */
  --abs-128: 12.8rem;

  /* Absolute size/spacing: 19.2rem (192px) */
  --abs-192: 19.2rem;

  /* Absolute size/spacing: 25.6rem (256px) */
  --abs-256: 25.6rem;
}
