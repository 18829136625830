.searchField {
  width: 220px;
}

.gridWrapper {
  padding: var(--gutter) var(--gutter--huge);
}

.biddingTypeTooltip {
  color: var(--grey-300);
  margin-left: var(--abs-8);
  display: inline-flex;
  align-items: center;
  margin-top: var(--abs-8);
}

.cpaInvalidValue {
  color: var(--orange-600);
}
