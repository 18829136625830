input.c-input--error {
  background: var(--red-200);
  border: 1px solid var(--red-700);
  color: var(--red-700);
}

.c-input--error:focus {
  box-shadow: 0 0 0 0.1rem var(--red-500);
  outline-color: var(--red-500);
}
