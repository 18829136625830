.u-color--juri-lighter {
  color: var(--grey-500);
}

.u-color--juri-light {
  color: var(--grey-600);
}

.u-color--blue-lighter {
  color: var(--blue-400);
}

.u-color--blue-light {
  color: var(--blue-500);
}

.u-color--orange-light {
  color: var(--orange-600);
}

.u-color--red-700 {
  color: var(--red-700);
}

.u-color--green-700 {
  color: var(--green-700);
}
