.inputField {
  width: 346px;
  border-radius: var(--border-radius);
  margin-bottom: var(--gutter--medium);
}

.inputError {
  border: 1px solid var(--red-700);
  background-color: var(--red-200);
}

.dashboardHeaderNameWrapper {
  flex-direction: column;
}

.dashboardHeaderNameWrapper:not(.isEdited) {
  margin-left: var(--gutter);
}

.dashboardHeaderName {
  display: flex;
  gap: var(--abs-12);
}

.title {
  font-size: var(--font-size--h-small);
}
