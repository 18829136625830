button:focus {
  outline: 0;
}

.e-button {
  color: var(--white);
  border-radius: 2px;
  border: 1px solid transparent;
  font-weight: 700;
  height: 44px;
  padding: 0 var(--gutter--medium);
  width: auto;
  font-size: var(--font-size--t-small);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  position: relative;
}

.e-button--no-style {
  background-color: transparent;
  border: 0;
  color: inherit;
}

.e-button--width-100 {
  width: 100% !important;
}

.e-button--oval {
  border-radius: 999em;
}
