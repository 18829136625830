.c-file-uploader__input {
  height: 17px;
  line-height: 17px;
}

.c-file-uploader__link {
  height: 15px;
  line-height: 15px;
  font-size: var(--font-size--tiny);
  margin-left: 36px;
}

.c-file-uploader__link:hover {
  text-decoration: underline;
}

.c-file-uploader {
  width: 100%;
  padding: var(--gutter--medium);
}

.c-file-uploader__disabled {
  pointer-events: none;
}

.c-file-uploader__disabled *,
.c-file-uploader__disabled img {
  color: var(--grey-500);
}
