.panelControl {
  display: flex;
  align-items: center;
  background-color: var(--blue-100);
  height: 52px;
  padding-top: var(--gutter--small);
  padding-bottom: var(--gutter--small);
}

.panelControlHotelListing {
  margin-top: var(--gutter);
}

.bidFormContainer {
  margin-right: var(--abs-16);
  margin-left: auto;
}

.nameSelectionIcon {
  width: var(--abs-20);
  height: var(--abs-20);
  cursor: pointer;
  margin-right: var(--gutter--medium);
}
