.alert {
  padding: var(--abs-12);
  border: solid var(--abs-1) var(--border-color);
  border-radius: var(--abs-2);
  background-color: var(--background-color);
  gap: var(--abs-12);
  display: flex;
  align-items: center;
}

.content {
  flex: 1;
}

.success {
  --border-color: var(--green-300);
  --background-color: var(--green-100);
}

.error {
  --border-color: var(--red-300);
  --background-color: var(--red-100);
}

.warning {
  --border-color: var(--orange-300);
  --background-color: var(--orange-100);
}

.close {
  cursor: pointer;
}
