.c-custom-filters__popover {
  z-index: 50;
}

.c-custom-filters__popover-filter-selection {
  max-height: 358px;
  overflow-y: scroll;
}

.c-custom-filters__popover-filter-set-selection {
  max-height: 448px;
  overflow-y: scroll;
}
