.c-tooltip-wrapper__tip {
  background-color: green;
}

.c-tooltip-wrapper__tip:before {
  top: 0;
  z-index: 4;
  left: 12px;
  width: 18px;
  height: 8px;
  background: var(--white) !important;
}

.c-tooltip-wrapper__message {
  line-height: 12px !important;
  overflow-wrap: anywhere;
}

.c-tooltip-wrapper__bottom-leading {
  padding-right: 10px;
}

.c-tooltip-wrapper__bottom-center {
  padding-right: 28px;
}
