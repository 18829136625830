.c-pageFooter {
  height: 187px;
  /*margin-top: 30px;  TODO necessary changes to make footer visible on the second fold */
}

.c-pageFooter div {
  height: 50%;
}

.c-pageFooter__privacy_link {
  color: var(--white);
}

.c-pageFooter__privacy_link:hover {
  text-decoration: underline;
}
