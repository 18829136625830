.c-area-pos > div {
  width: 350px;
}

.c-area-pos + .c-area-options {
  height: 210px;
}

.c-area-pos + .c-area-options > .c-area-options__options {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}

.c-area-pos + .c-area-options > .c-area-options__options.c-area-options-locales {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
