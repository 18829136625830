.customDashboard {
  min-height: 70vh;
}

.gridLayout {
  position: relative;
  transition: height 200ms ease;
}

.gridLayout :global(.react-grid-item) {
  height: 302px;
  box-sizing: border-box;
}

.gridLayout :global(.react-grid-item:not(.react-grid-placeholder)) {
  background: var(--grey-300);
}

.gridLayout :global(.react-grid-item.resizing) {
  opacity: 0.9;
}

.gridLayout :global(.react-grid-item .text) {
  font-size: var(--font-size--h-small);
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: var(--font-size--h-small);
}

.gridLayout :global(.react-grid-item .minMax) {
  font-size: var(--font-size--tiny);
}

.gridLayout :global(.react-grid-item .add) {
  cursor: pointer;
}

.gridLayout :global(.react-grid-dragHandleExample) {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.gridLayout :global(.react-grid-item) {
  transition: all 200ms ease;
  transition-property: left, top;
}

.gridLayout :global(.react-grid-item.cssTransforms) {
  transition-property: transform;
}

.gridLayout :global(.react-grid-item.resizing) {
  z-index: 1;
  will-change: width, height;
}

.gridLayout :global(.react-grid-item.react-draggable-dragging) {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.gridLayout :global(.react-grid-item:focus-within) {
  z-index: 3;
}

.gridLayout :global(.react-grid-item.react-grid-placeholder) {
  border: 1px solid var(--blue-700);
  border-radius: 2px;
  background: var(--blue-400);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.gridLayout :global(.react-grid-item > .react-resizable-handle) {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
