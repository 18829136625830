.modalBody {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-right: var(--gutter--large);
  padding-left: var(--gutter--large);
}

.modalInfo {
  margin-bottom: var(--gutter);
}

.dashboardNameLabel {
  font-weight: bold;
}

.inputError {
  border: 1px solid var(--red-700);
  background-color: var(--red-200);
}

.nameField {
  width: 346px;
  margin-bottom: var(--gutter--large);
  margin-top: var(--gutter--tiny);
}

.errorHint {
  width: 288px;
}

.dashboardTemplateSelector {
  display: flex;
}

.dashboardOption {
  margin-right: var(--gutter--large);
}

.unselectedOption {
  margin-bottom: var(--gutter--tiny);
}

.radioButtonWrapper {
  margin-top: var(--gutter--small);
  margin-left: var(--gutter);
}
