.e-button--link {
  color: var(--blue-700);
  background-color: transparent;
  border: 0;
  font-weight: normal;
}

.e-button--link-tiny {
  color: var(--blue-700);
  background-color: transparent;
  border: 0;
  font-weight: normal;
  font-size: var(--font-size--tiny);
  height: auto;
}

.e-button--link--grid {
  color: var(--blue-700);
  background-color: transparent;
  border: 0;
  font-weight: normal;
  height: 12px;
  line-height: 14px;
  font-size: var(--font-size--tiny);
  padding: 0;
}

.e-button--link[disabled] {
  color: var(--blue-300);
}
