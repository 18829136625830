.loading {
  --width: 60px;
  width: var(--width);
  height: var(--abs-16);
  display: inline-block;
}

.dot {
  --size: var(--abs-16);
  width: var(--size);
  height: var(--size);
  display: inline-block;
  border-radius: 100%;
  border: solid var(--abs-2) white;
  background-color: var(--color);
  animation: float 1.25s infinite ease-in-out;
  position: absolute;
  left: 0;
}

.dot:nth-child(1) {
  animation-delay: 0.2s;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.red {
  --color: var(--red-700);
}

.orange {
  --color: var(--orange-700);
}

.blue {
  --color: var(--blue-700);
}

@keyframes float {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(var(--width) - var(--size)));
  }
  100% {
    transform: translateX(0);
  }
}
