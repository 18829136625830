.c-area-selector {
  min-width: 200px;
  height: 200px;
  padding: 16px 0 0 16px;
}

.c-area-selector > li {
  padding-left: 0;
}

.c-area-selector__selected {
  border: 2px solid var(--grey-700);
  border-width: 0 2px 0 0;
}
