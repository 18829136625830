.c-menu {
  top: 110%;
  width: 100%;
  min-width: 216px;
  right: 0;
  z-index: 16;
}

.c-menu__item:hover {
  background-color: var(--grey-200);
}

.c-menu__item-header:hover {
  background-color: none;
}

.c-menu__item-footer:hover {
  background-color: var(--white) !important;
}

.c-menu__item {
  cursor: pointer;
  display: flex;
  min-height: 48px;
  line-height: 48px;
  font-size: var(--font-size--h-tiny);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding: var(--gutter--medium) var(--gutter--small);
}

.c-menu__item-remove-button {
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  color: var(--red-700);
}
