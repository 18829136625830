.selectDropdownContainer {
  display: flex;
  align-items: center;
  border-radius: var(--abs-2);
  border: none;
  width: var(--rel-100);
}

.selectDropdownControl,
.selectDropdownControlFocused {
  border: 1px solid var(--grey-300) !important;
  border-radius: var(--border-radius) !important;
  box-shadow: none !important;
  min-height: 36px !important;
  align-items: center !important;
  width: var(--rel-100);
}

.selectDropdownControlFocused {
  border-color: var(--blue-700) !important;
  height: 36px !important;
}

.selectDropdownValueContainer {
  padding: 0 8px 0 12px !important;
  font-size: var(--font-size--h-tiny) !important;
}

.selectDropdownSingleValue,
.selectDropdownMenu,
.selectDropdownOptionFocused,
.selectDropdownOption {
  font-size: var(--font-size-14) !important;
  color: var(--grey-700) !important;
  box-sizing: border-box !important;
  margin: 0 !important;
  cursor: pointer !important;
}

.selectDropdownSingleValue {
  color: var(--grey-700) !important;
}

.selectDropdownMenu {
  margin-top: 0 !important;
  box-shadow: var(--box-shadow-45) !important;
  border-radius: var(--border-radius) !important;
  padding: 12px !important;
}

.selectDropdownOption {
  padding: 0 12px !important;
  margin-bottom: 2px !important;
  min-height: 44px !important;
  color: var(--grey-700) !important;
  display: flex !important;
  align-items: center;
}

.selectDropdownOptionFocused {
  background-color: var(--grey-200) !important;
  padding: 0 12px !important;
  margin-bottom: 2px !important;
  min-height: 44px !important;
  border-radius: var(--border-radius) !important;
  display: flex !important;
  align-items: center;
}

.selectDropdownMenuList {
  padding: 0 !important;
}

.selectDropdownIndicatorFocused,
.selectDropdownIndicator {
  padding: 0 12px 0 0 !important;
  color: var(--grey-700) !important;
  height: 24px !important;
  align-items: center !important;
}

.selectDropdownIndicator {
  transform: rotateX(0deg) !important;
}

.selectDropdownIndicatorFocused > svg,
.selectDropdownIndicator > svg {
  width: 24px !important;
  height: 24px !important;
}

.selectDropdownIndicatorFocused {
  transform: rotateX(180deg) !important;
}

.selectDropdownPlaceholder {
  margin: 0 !important;
}

.selectDropdownInput,
.selectDropdownInput > input {
  height: 21px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.selectDropdownIndicatorSearch,
.selectDropdownIndicatorSearchFocused {
  display: flex !important;
  align-items: center !important;
  padding-right: 12px !important;
  height: 34px !important;
}

.selectDropdownIndicatorSearch > svg {
  width: 24px;
  height: 24px;
  color: var(--grey-700) !important;
}

.selectDropdownCustomInput {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

.selectDropdownCustomInput input {
  border: none !important;
  height: 21px !important;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.selectDropdownLoadingMessage {
  height: 44px;
  display: flex;
  align-items: center;
}

.selectDropdownLoadingIndicator {
  display: none !important;
}
.indicatorSeparator {
  display: none;
}
