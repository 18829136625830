.horizontalScrollContainer {
  overflow: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: auto;
  scrollbar-width: none;
}
.content {
  display: inline-flex;
  width: 100%;
  height: 36px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  align-items: center;
  gap: var(--abs-8);
  scroll-snap-type: x mandatory;
  z-index: var(--z-index-0);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.content::-webkit-scrollbar {
  display: none;
}
.elevatedIcon {
  color: var(--grey-700) !important;
}
.content > * {
  scroll-snap-align: end;
}
.horizontalScrollContainer > button {
  z-index: var(--z-index-1);
  position: absolute;
  background-color: var(--white);
  display: none;
}
.horizontalScrollContainer > button:last-child {
  right: var(--abs-2);
}
.horizontalScrollContainer:hover > button {
  display: inline-flex;
}

.leftFade::before {
  content: '';
  position: absolute;
  pointer-events: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(270deg, #f9fafa00 20%, #f9fafa 100%);
  height: 36px;
  width: 36px;
}
.rightFade::after {
  content: '';
  position: absolute;
  pointer-events: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(270deg, #f9fafa 20%, #f9fafa00 100%);
  height: 36px;
  width: 36px;
  right: var(--abs-2);
}
