.widget-title-edit {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.widget-title-edit input {
  margin: 0;
  height: 36px;
  font-size: 14px;
  flex: 1;
}
