.comparisonCell {
  margin-right: var(--abs-8);
  display: inline-flex;
  align-items: center;
}
.positiveTrendCell {
  color: var(--green-700);
  stroke: var(--green-700);
}
.negativeTrendCell {
  color: var(--red-700);
  stroke: var(--red-700);
}
.unchangedCell {
  color: var(--grey-500);
}
.comparisonCell > svg {
  margin-left: var(--abs-4);
}
