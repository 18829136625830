.container {
  margin: var(--abs-16) auto 7vw;
  max-width: 450px;
  max-height: 500px;
  box-shadow: var(--box-shadow);
  background: var(--white);
}

.unauthorized {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: var(--abs-32);
  margin-top: var(--abs-32);
}

.unauthorized > h1 {
  margin-bottom: 8px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--abs-32);
  margin-right: var(--abs-12);
  margin-top: var(--abs-16);
}
