.box {
  display: flex;
  justify-content: space-between;
  margin: var(--abs-0) var(--abs-12);
  --border-color: var(--blue-300);
  border: var(--border-1);
  border-radius: var(--abs-2);
}

.box:hover {
  --border-color: var(--blue-500);
}

.labelBox {
  margin-left: var(--gutter--medium);
}

.details {
  color: var(--grey-600);
}

.changeButton {
  margin: var(--abs-0) var(--abs-12);
  font-size: var(--font-size-14);
  display: flex;
  align-items: center;
}
