.members {
  padding: var(--gutter);
}

.filters {
  display: flex;
  align-items: center;
  gap: var(--gutter);
  padding-block-end: var(--gutter);
}

.filters .searchInput,
.filters .partnerSelect {
  margin: 0;
}
