/*
 * # Popover's outer wrapper to to grant the `.popover` a `max-width` which could
 * exceed its parent within a `position: absolute;` context without using a fixed width.
 *
 * The popover is positioned via this wrapper's modifier classes.
 */

.popoverWrapper {
  pointer-events: none;
  position: absolute;
  width: 1280px;
  z-index: 5;
}

/*
 * # Popover
 */

.popover {
  background-color: var(--white);
  border-radius: 2px;
  box-shadow: var(--box-shadow-45);
  display: inline-block;
  pointer-events: auto;
  position: relative;
  /*
     * ## Popover's arrow basic styles
     */
}

.popover:focus {
  outline: none;
}

@supports (outline-offset: 0) {
  .popover:focus {
    outline: 1px dotted var(--grey-800);
    outline-offset: -3px;
    box-shadow: none;
  }
}

.popover::before,
.popover::after {
  background-color: var(--white);
  content: '';
  margin: 0;
  height: 17px;
  position: absolute;
  width: 17px;
}

.popover::before {
  box-shadow: var(--box-shadow-45);
  z-index: 1;
}

.popover::after {
  z-index: 2;
}

.popoverBody {
  position: relative;
  z-index: 3;
  /*
         * ### If the popover is used in combination with a parent element's :hover state this makes sure that there is no ":hover-gap" which makes the popover disappear
         */
}

.popoverBody::before {
  display: block;
  content: '';
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 8px;
}

.popover .paragraph {
  font-size: 14px;
  line-height: 1.5;
}

.popover .btn {
  margin-bottom: 16px;
}

.popover .btn--icon-only {
  margin: 0;
}

/*
 * # Config
 */

/*
 * ## Placeholders
 */

.bottom {
  top: 100%;
  margin-top: 8px;
}

.bottom > .popover::before {
  margin-top: -8px;
  top: 0;
  transform: rotate(45deg);
}

.bottom > .popover::after {
  height: 17px;
  top: 0;
  width: 28px;
}

/*
 * # Popover on the bottom side of the parent element.
 */

.bottom.trailing {
  right: 0;
}

.bottom.trailing > .popover {
  float: right;
}

.bottom.trailing > .popover::before {
  right: 16px;
}

.bottom.trailing > .popover::after {
  right: 10px;
}

.bottom.leading {
  left: 0;
}

.bottom.leading > .popover {
  float: left;
}

.bottom.leading > .popover::before {
  left: 16px;
}

.bottom.leading > .popover::after {
  left: 10px;
}

/*
 * # Popover on the top side of the parent element.
 */

.top {
  bottom: 100%;
  margin-bottom: 8px;
}

.top > .popover::before {
  margin-bottom: -8px;
  bottom: 0;
  transform: rotate(45deg);
}

.top > .popover::after {
  height: 17px;
  bottom: 0;
  width: 28px;
}

.leading.center {
  top: 50%;
  transform: translateY(-50%);
}

.leading.center > .popover::before {
  top: 50%;
  margin-top: -8px;
}

.leading.center > .popover::after {
  top: calc(50% - 14px);
}

.leading.top {
  top: 0;
}

.leading.top > .popover::before {
  top: 16px;
}

.leading.top > .popover::after {
  top: 10px;
}

.center,
.center > .popover {
  left: 50%;
  transform: translateX(-50%);
}

.center > .popover::before {
  left: calc(50% - 9px);
}

.center > .popover::after {
  left: calc(50% - 15px);
}

.top.leading {
  left: 0;
}

.top.leading > .popover {
  float: left;
}

.top.leading > .popover::before {
  left: 16px;
}

.top.leading > .popover::after {
  left: 10px;
}

.top.trailing {
  right: 0;
}

.top.trailing > .popover {
  float: right;
}

.top.trailing > .popover::before {
  right: 16px;
}

.top.trailing > .popover::after {
  right: 10px;
}

.trailing.center {
  top: 50%;
  transform: translateY(-50%);
}

.trailing.center > .popover::before {
  top: 50%;
  margin-top: -8px;
}

.trailing.center > .popover::after {
  top: calc(50% - 14px);
}

.trailing.top {
  top: 0;
  left: 100%;
  margin-left: 8px;
  width: max-content;
}

.trailing.top .popover {
  float: left;
}

.trailing.top .popover::before {
  top: 16px;
  left: -8px;
  right: auto;
  transform: rotate(45deg);
}

.trailing.top .popover::after {
  height: 28px;
  top: 10px;
  left: 0;
  width: 17px;
}

.onboarding {
  background-color: var(--blue-700);
  max-width: 320px;
  font-weight: normal;
  white-space: normal;
  line-height: 21px;
  margin-top: var(--gutter--small);
}

.onboarding::before {
  background-color: var(--blue-700);
}

.onboarding::after {
  background-color: var(--blue-700);
}

.onboarding > .popoverBody {
  display: flex;
  padding: var(--gutter--medium);
}

.notificationDrawer {
  position: absolute;
  right: -57px;
}

.notificationDrawer.popover::before {
  left: 248px;
}

.notificationDrawer.popover::after {
  left: 242px;
}

.userDrawer {
  right: -7px;
}
