.reportHistoryPage {
  margin: auto;
  margin-block-start: var(--gutter);
  margin-block-end: var(--gutter);
  max-width: 1400px;
  min-height: 55vh;
  padding-block-start: var(--gutter--large);
  padding-inline-start: var(--gutter--large);
  padding-inline-end: var(--gutter--large);
}

.backIcon {
  transform: rotate(180deg);
}

.header {
  display: flex;
  border-block-end: solid 1px var(--grey-200);
  padding-block-end: var(--gutter);
  padding-block-start: var(--gutter--large);
  justify-content: space-between;
}
