.e-button--big {
  font-size: var(--font-size--small);
  height: 52px;
  line-height: 52px;
}

.e-button--small {
  font-size: var(--font-size--h-tiny);
  height: 36px;
  line-height: 36px;
}
