.c-hotel-not-found__card {
  box-shadow: var(--box-shadow-45);
}

.c-hotel-not-found {
  padding-top: 42px;
  min-height: 52vh;
  width: 504px;
}

.c-hotel-not-found ul li {
  margin-left: var(--gutter);
}

.c-hotel-not-found ul li:before {
  content: '\2022';
  color: var(--black);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.c-hotel-not-found__left {
  width: 346px;
}

.c-hotel-not-found__left h1 {
  font-size: 21px;
}
