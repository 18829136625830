.e-button--default:focus,
.e-button--default:hover,
.e-button--default:visited {
  background: var(--grey-800);
  color: var(--white);
}

.e-button--default:active {
  background: var(--white);
}

.e-button--default {
  background: var(--grey-600);
  border-color: var(--grey-700);
  border-bottom-color: var(--grey-800);
  color: var(--white);
}

.e-button--default[disabled] {
  background: var(--grey-500);
  border-color: var(--grey-500);
  border-bottom-color: var(--grey-600);
}
