/*
 * Line height variables for typography
 */
:root {
  /* Line height none */
  --font-line-height-100: 1;

  /* Line height tight */
  --font-line-height-125: 1.25;

  /* Line height normal */
  --font-line-height-150: 1.5;

  /* Line height loose */
  --font-line-height-200: 2;
}
