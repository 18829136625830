.c-revert {
  height: 36px;
}

.c-revert__menu {
  top: 5px;
}

.c-revert__tooltip {
  margin-top: 10px;
}

.c-revert__button {
  padding: var(--gutter--t-small);
  color: var(--grey-700);
}

.c-revert__button:hover,
.c-revert__button--open,
.c-revert__button--open svg {
  background: var(--grey-600);
  color: var(--white);
}

.c-revert__item {
  width: 300px;
}

.c-revert__item-subtitle {
  font-size: 12px;
}
