.biddingTypeDropdownTag {
  color: var(--grey-700);
  background-color: var(--blue-200);
  gap: var(--abs-0);
  font-weight: var(--font-weight-700);
}

.biddingTypeDropdownTag * {
  display: inline-flex;
  align-items: center;
  gap: var(--abs-8);
}
.biddingTypeDropdownTag:hover svg {
  color: var(--grey-700) !important;
}
.biddingTypeDropdownTag:focus {
  border-color: var(--blue-500);
}

.biddingTypeDropdownContainer {
  display: inline-flex;
  align-items: center;
  margin-inline-start: var(--abs-12);
}

.biddingTypeDropdownInfoIcon {
  color: var(--grey-300);
  margin-left: var(--abs-8);
  display: inline-flex;
  align-items: center;
  margin-top: var(--abs-8);
}
