.timePeriodSelector {
  max-height: 565px;
  width: 323px;
  box-shadow: var(--box-shadow-45);
  border-radius: var(--abs-2);
  display: flex;
  flex-direction: column;
  background: var(--white);
}

.timePeriodTitle {
  padding: var(--abs-16) var(--abs-24) var(--abs-12);
  font-size: var(--font-size-16);
}

.compareToTitle {
  padding: var(--abs-20) var(--abs-24) var(--abs-0);
  font-size: var(--font-size-14);
}

.customHeader {
  margin: var(--abs-0) var(--abs-16);
}

.customHeaderChange {
  color: var(--blue-700);
}

.customOptions {
  margin: var(--abs-0) var(--abs-12);
  padding: var(--abs-12);
  cursor: pointer;
}
.customOptions:hover {
  background: var(--grey-200);
}
