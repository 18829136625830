.button {
  background: var(--transparent);
  border: none;
  display: inline-flex;
  border-radius: 100%;
}

.button:hover * {
  background: var(--grey-200);
}

.button:disabled {
  cursor: not-allowed;
  color: var(--grey-300);
}
.elevated {
  border-radius: 100%;
  box-shadow: var(--abs-0) var(--abs-1) var(--abs-4) var(--abs-0) rgba(0, 0, 0, var(--alpha-25));
}
