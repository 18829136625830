.c-admin {
  margin: var(--gutter) auto 7vw;
  max-width: 1400px;
}

@media screen and (max-width: 1920px) {
  .c-admin {
    max-width: 1200px;
  }
}
