.noResultsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--abs-12);
  align-items: center;
  text-align: center;
  padding: var(--abs-32);
  font-size: var(--font-size-14);
  color: var(--grey-600);
}
