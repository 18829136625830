.c-user-popover:first-child {
  margin-right: -30px;
}

.c-user-popover__menu {
  min-width: 204px;
}

.c-user-popover__menu li:not(:first-child):hover {
  background-color: var(--grey-200);
}

.c-user-popover__menu li:first-child {
  font-size: 12px;
}
