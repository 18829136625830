.u-cursor--pointer {
  cursor: pointer;
}

.u-cursor--grab {
  cursor: grab;
}

.u-cursor--grabbing {
  cursor: grabbing;
}

.u-cursor--default {
  cursor: default;
}
