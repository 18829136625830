.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: var(--box-shadow-45);
  border-radius: var(--abs-2);
  margin-top: var(--abs-8);
  width: 100%;
  background-color: var(--white);
}
.main {
  display: flex;
  flex-direction: column;
  gap: var(--abs-8);
  padding: var(--abs-24);
  width: 100%;
}
.footer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--grey-300);
  padding: var(--abs-24);
  width: 100%;
  gap: var(--abs-16);
}
.footerEnd {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: var(--abs-12);
}
