.verticalDivider {
  width: 1px;
}

.large {
  height: 36px;
}

.small {
  height: 24px;
}

.stretch {
  height: auto;
  align-self: stretch;
}
