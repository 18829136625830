.report-definition-picker {
  width: 100%;
  margin-right: 5px;
}
.report-definition-picker > button {
  width: 100%;
}
.report-definition-picker > button > span {
  width: 100%;
  text-align: left;
}
.report-definition-picker > ol {
  width: 100%;
}
