.validationSummary {
  border: solid 1px var(--grey-200);
  padding: var(--gutter);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--gutter--large) 0;
}

.errors {
  color: var(--red-700);
}

.warnings {
  color: var(--orange-700);
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--abs-12);
}

.loading {
  position: relative;
}
