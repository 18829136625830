.cell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  position: relative;
}

.downloadSelector {
  position: absolute;
  top: 25px;
  right: -180px;
  width: max-content;
  min-width: 200px;
  z-index: 1;
  box-shadow: var(--box-shadow);
  background-color: white;
  border-radius: 2px;
  padding: 4px;
  text-align: left;
  font-size: 14px;
}

.downloadSelectorItem {
  line-height: 45px;
  cursor: pointer;
}

.downloadSelectorItem:hover {
  background-color: var(--grey-200);
}

.downloadSelectorIcon {
  margin: 0 var(--gutter--small);
  height: 24px;
  width: 24px;
  vertical-align: middle;
}

.downloadIcon {
  height: 18px;
  width: 18px;
  position: relative;
  top: 2px;
}

.downloadButton {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.downloadButton:hover {
  text-decoration: underline;
}

.downloadButton:hover > .downloadIcon {
  background-color: var(--grey-200);
  border-radius: 50%;
}

.downloadIcon > path {
  stroke: var(--grey-600);
}

.downloadButton:hover > .downloadIcon > path {
  stroke: var(--grey-700);
}

.loading {
  transform: translateY(25%);
}

.loading > div {
  margin-right: 0;
}

.disabled {
  color: var(--grey-500);
}
