.checkbox-select {
  max-height: 565px;
  width: 323px;
  box-shadow: var(--box-shadow-45);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.checkbox-select-header {
  padding: 24px;
  padding-bottom: 0;
}

.checkbox-select-header .title {
  margin-bottom: 12px;
}

.checkbox-select-header input {
  border-radius: 2px;
}

.checkbox-select-header input:not(:focus) {
  border: solid 1px var(--grey-300);
}

.checkbox-select-header button {
  color: var(--blue-700);
  background-color: transparent;
  margin-top: var(--gutter);
  margin-bottom: var(--gutter--small);
  border: none;
  font-size: 14px;
}

.checkbox-select .options {
  padding: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.checkbox-select .options li {
  padding: 0;
}

.checkbox-select .options.has-sub-labels .option-item {
  height: 54px;
}

.option-item {
  display: flex;
  align-items: center;
  gap: var(--gap);
  border: solid 1px transparent;
  border-radius: 2px;
  font-size: 14px;
  line-height: 21px;
  height: 44px;
  padding: 10px 12px;
  cursor: pointer;
  font-weight: var(--font-weight-400);
  --checkbox-width: var(--abs-12);
  --gap: var(--abs-12);
}

.option-item input {
  width: var(--checkbox-width);
  height: var(--checkbox-width);
  margin: 0;
}

.option-item .labels {
  width: calc(100% - var(--checkbox-width) - var(--gap));
}

.sub-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--grey-600);
}

.option-item__custom-header-active {
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 2px;
  font-size: 14px;
  line-height: 21px;
  padding: 6px 0;
  cursor: pointer;
  border: solid 1px var(--blue-300);
  background-color: var(--blue-200);
}

.option-item:hover:not(.option-item--selected) {
  background-color: var(--grey-200);
}

.option-item--selected {
  border: solid 1px var(--blue-300);
  background-color: var(--blue-200);
}

.option-item--selected:hover {
  border: solid 1px var(--blue-500);
}

.option-item--disabled {
  pointer-events: none;
}

.checkbox-select-footer {
  border-top: solid 1px var(--grey-200);
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-select-footer p {
  color: var(--grey-600);
  font-size: 12px;
  flex: 1;
  text-align: center;
}

.checkbox-select-footer button {
  flex: 1;
}
