.c-sponsored-campaigns {
  margin: var(--gutter) auto 7vw;
  max-width: 1400px;
  min-height: 70vh;
}

@media screen and (max-width: 1920px) {
  .c-sponsored-campaigns {
    max-width: 1200px;
  }
}

.c-sponsored-campaigns table td {
  height: 26px;
  line-height: 26px;
}

.c-sponsored-campaigns__input {
  width: 220px;
}

.c-sponsored-campaigns__button-background,
.c-sponsored-campaigns__button-background:not(svg) {
  background-color: var(--blue-700);
}

.c-sponsored-campaigns__button-background:hover {
  background-color: var(--blue-800);
}

.c-sponsored-campaigns__button-color,
.c-sponsored-campaigns__button-color svg {
  color: var(--white);
}
