.c-input-filter {
  width: 200px;
  height: 36px;
  margin-top: 1px;
  margin-bottom: 0;
}

.c-input-filter-separator {
  margin-inline-end: var(--gutter);
}

.c-input-filter__disabled {
  background-color: var(--grey-200);
}

.c-input-filter__padding {
  padding-top: 1px;
  padding-left: 1px;
}

.c-input-filter__symbol:after {
  position: absolute;
  top: 7px;
  content: attr(id);
  right: 26px;
}

.c-input-filter__space-no-trash {
  width: 24px;
}
