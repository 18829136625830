.tabs {
  min-height: calc(100vh - 230px);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background: white;
}

.content {
  padding: var(--gutter--small);
}

.tabsNavigation {
  display: flex;
  color: var(--grey-600);
  font-size: var(--font-size--h-tiny);
  border-block-end: 1px solid var(--grey-300);
  padding-inline-start: var(--gutter);
  padding-block-start: var(--gutter--small);
}

.leading {
  padding-inline-end: var(--gutter);
  display: flex;
  align-items: center;
}

.tab {
  cursor: pointer;
  padding: var(--gutter--small);
  padding-inline-start: var(--gutter--medium);
  padding-inline-end: var(--gutter--medium);
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.tab:hover {
  background-color: var(--grey-200);
  border-block-end: 2px solid var(--grey-300);
}

.tab.disabled,
.tabGroupButton.disabled {
  color: var(--grey-400);
  pointer-events: none;
}

.tab.active,
.tabGroupButton.active {
  border-block-end: 2px solid var(--grey-700);
  color: var(--grey-700);
}

.tabGroupButton {
  border: none;
  color: var(--grey-600);
  background-color: white;
  height: 39px;
  border-radius: 0;
}
