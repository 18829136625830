.button {
  border-radius: var(--abs-2);
  font-weight: var(--font-weight-700);
  color: var(--color);
  background-color: var(--background-color);
  border-width: var(--abs-1);
  border-color: var(--border-color, var(--background-color, var(--transparent)));
  border-style: var(--border-style);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--abs-8);
  position: relative;
  padding-inline: var(--abs-12);
  --loading-animation-duration: 0.3s;
  font-size: var(--font-size-14);
}

/**
 * Button states
 */
.button:hover {
  color: var(--active-color, var(--color));
  background-color: var(--active-background-color, var(--background-color));
  border-color: var(--active-border-color, var(--border-color));
}

.button:focus {
  outline: none;
}

.button:focus-visible {
  color: var(--active-color, var(--color));
  background-color: var(--active-background-color, var(--background-color));
  border-color: var(--active-border-color, var(--border-color));
  outline: var(--abs-2) solid var(--blue-700);
  outline-offset: var(--abs-2);
}

.button:active {
  color: var(--active-color, var(--color));
  background-color: var(--active-background-color, var(--background-color));
  border-color: var(--active-border-color, var(--border-color));
}

.button:disabled {
  cursor: not-allowed;
  color: var(--disabled-color, var(--color));
  background-color: var(--disabled-background-color, var(--background-color));
  border-color: var(--disabled-border-color, var(--border-color));
}

.regular {
  height: var(--abs-36);
}

.large {
  height: var(--abs-44);
}

.primary {
  --color: var(--white);
  --background-color: var(--blue-700);
  --border-color: var(--blue-700);
  --active-background-color: var(--blue-800);
  --active-border-color: var(--blue-800);
  --disabled-background-color: var(--blue-300);
  --disabled-border-color: var(--blue-300);
}

.secondary {
  --color: var(--white);
  --background-color: var(--grey-600);
  --border-color: var(--grey-600);
  --active-background-color: var(--grey-700);
  --active-border-color: var(--grey-700);
  --disabled-background-color: var(--grey-300);
  --disabled-border-color: var(--grey-300);
}

.tertiary {
  --border-width: var(--abs-1);
  --color: var(--grey-700);
  --background-color: var(--white);
  --border-color: var(--grey-400);
  --active-border-color: var(--grey-700);
  --disabled-background-color: var(--white);
  --disabled-border-color: var(--grey-400);
  --disabled-color: var(--grey-400);
}

.selected {
  --border-width: var(--abs-1);
  --color: var(--grey-700);
  --background-color: var(--blue-200);
  --border-color: var(--blue-300);
  --active-border-color: var(--blue-500);
  --disabled-background-color: var(--blue-200);
  --disabled-border-color: var(--blue-200);
  --disabled-color: var(--grey-400);
}

.delete {
  --color: var(--white);
  --background-color: var(--red-700);
  --border-color: var(--red-700);
  --disabled-background-color: var(--red-300);
  --disabled-border-color: var(--red-300);
  --disabled-color: var(--white);
  --active-color: var(--white);
  --active-background-color: var(--red-800);
  --active-border-color: var(--red-800);
}

.loading {
  position: absolute;
  opacity: 0;
  transform: translateY(-50%);
  animation-name: fadeInDown;
  animation-duration: var(--loading-animation-duration);
  animation-fill-mode: forwards;
  animation-delay: calc(var(--loading-animation-duration) / 2);
}

.text,
.icon {
  transition:
    opacity var(--loading-animation-duration),
    transform var(--loading-animation-duration);
}

.button:has(.loading) .text,
.button:has(.loading) .icon {
  opacity: 0;
  transform: translateY(50%);
}

@keyframes fadeInDown {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.flat {
  --background-color: var(--transparent);
  --border-color: var(--transparent);
  --color: var(--blue-700);
  --disabled-color: var(--grey-400);
  font-weight: var(--font-weight-400);
  padding-inline: var(--abs-0);
  height: var(--abs-24);
}
