.fullscreenModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background: var(--black-a75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.fullscreenModalContainer {
  width: 400px;
  position: fixed;
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 24px;
  border-radius: var(--border-radius);
}

.title {
  margin-bottom: var(--gutter--large);
}
