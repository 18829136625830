.c-wrapper-error-hint {
  border-radius: 2px;
  font-size: 0.875rem;
  margin-top: 2.66667px;
  display: inline-block;
  color: var(--red-700);
  border: 1px solid var(--red-700);
  padding: 8px;
  z-index: 12;
  position: absolute;
  min-width: 64px;
  width: 164px;
  box-shadow: var(--box-shadow-45);
  background-color: var(--white);
  top: 36px;
  line-height: 18px;
}

.c-wrapper-error-hint:after,
.c-wrapper-error-hint:before {
  bottom: 100%;
  left: 24px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.c-wrapper-error-hint:after {
  border-color: transparent transparent var(--white);
  border-width: 8px;
  margin-left: -8px;
}

.c-wrapper-error-hint:before {
  border-color: transparent transparent var(--red-700);
  border-width: 9px;
  margin-left: -9px;
}
