.formContainer {
  display: flex;
  align-items: center;
  gap: var(--abs-16);
  line-height: normal;
}

.formHeaderContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.formHeaderLabel {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  color: var(--grey-700);
}

.setToButton {
  font-size: var(--font-size-12);
  height: auto;
}

.setToMenu {
  width: 155px;
}

.popoverHeader {
  font-size: var(--font-size-14);
  padding: var(--abs-0);
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--abs-8);
}

.inputContainer input {
  font-size: var(--font-size-14);
}

[class*='input_decoration'] {
  font-weight: var(--font-weight-700);
  padding: 1.8rem;
}

.inputWarning {
  border-color: var(--orange-700) !important;
  background-color: var(--orange-100) !important;
}

.warningPopoverContainer {
  position: absolute;
  left: var(--abs-0);
  bottom: -4px;
}
.warningPopover {
  font-size: var(--font-size-12);
  border-color: var(--orange-700);
  background-color: var(--orange-100);
  padding: var(--abs-8);
}

.tooltip {
  border: 1px solid var(--orange-700);
  color: var(--orange-700) !important;
  background-color: var(--white) !important;
  max-width: 210px !important;
  z-index: 100;
}

.tooltip:before {
  content: '';
  display: block;
  position: absolute;
  bottom: var(--rel-100);
  left: var(--abs-12);
  border: var(--abs-8) solid transparent;
  border-bottom-color: var(--orange-700);
}
.tooltip::after {
  border-width: calc(var(--abs-8) - var(--abs-1)) !important;
  border-bottom-color: var(--orange-100) !important;
  left: calc(var(--abs-12) + var(--abs-1)) !important;
  bottom: calc(100% - var(--abs-8));
}

.confirmDialog {
  color: var(--grey-700);
}
.confirmDialog svg {
  color: var(--grey-600);
}
.confirmDialogDesc {
  font-size: var(--font-size-14);
  color: var(--grey-600);
}
