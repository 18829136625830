.biddingTypeSelectorDropdownTag > ol {
  left: 0;
  padding: 0;
  width: 323px !important;
}

.options {
  padding: 12px;
  padding-right: 2px;
  padding-top: 0;
  --distanceFromTop: 175px;
  --heightOfOtherElementsInDropdown: 260px;
  max-height: min(
    350px,
    calc(100vh - var(--distanceFromTop) - var(--heightOfOtherElementsInDropdown))
  );
  overflow-y: scroll;
}

.options::-webkit-scrollbar {
  width: 10px;
}

.options::-webkit-scrollbar:hover {
  background-color: var(--grey-200);
}

.options::-webkit-scrollbar-thumb {
  border: 2px solid var(--transparent);
  background-clip: padding-box;
  -webkit-border-radius: 4px;
  background-color: var(--grey-300);
}

.options::-webkit-scrollbar-thumb:active {
  background-color: var(--grey-500);
}

.option p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.subLabel {
  color: var(--grey-600);
}

.footer {
  padding: 24px;
  border-top: solid 1px var(--grey-200);
}

.footer button {
  width: 100%;
}

.biddingTypeSelectorDropdownTag > button,
.biddingTypeSelectorDropdownTag > button > svg,
.biddingTypeSelectorDropdownTag > button:focus,
.biddingTypeSelectorDropdownTag > button:hover {
  border: none;
  background-color: var(--transparent) !important;
  margin: inherit;
  color: inherit !important;
  padding: inherit;
}
.biddingTypeSelectorDropdownTag > ol .options {
  text-align: left;
}

.biddingTypeSelectorDropdownTag ol > div:nth-child(2),
.biddingTypeSelectorDropdown > ol > div:nth-child(2) {
  padding-top: var(--abs-0);
}

.tagContainer {
  margin-left: var(--abs-12);
}

.biddingTypeSelectorDropdownTag > button > span {
  text-overflow: ellipsis;
  max-width: 200px;
  overflow-x: clip;
}

.subLabel {
}
