.tooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltipText {
  --padding: var(--abs-8);
  visibility: hidden;
  background-color: var(--black-a75);
  color: var(--white);
  border-radius: var(--abs-2);
  padding: var(--padding);
  position: absolute;
  opacity: 0;
  line-height: var(--font-line-height-150);
  font-size: var(--font-size-12);
  transition: opacity var(--duration-20);
  white-space: normal;
  white-space-collapse: collapse;
  width: max-content;
  max-width: 300px;
}

.tooltipText::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: var(--abs-8);
  border-color: transparent;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
  z-index: var(--z-index-4);
}

/* Position */
.top {
  bottom: calc(100% + var(--abs-8));
}

.top::after {
  top: calc(100% + var(--padding));
  border-top-color: var(--black-a75);
}

.top.center,
.bottom.center {
  left: 50%;
  transform: translateX(-50%);
}

.top.start,
.bottom.start {
  left: 0;
}

.top.end,
.bottom.end {
  right: 0;
}

.top.start::after,
.bottom.start::after {
  left: var(--abs-12);
  right: unset;
}

.top.end::after,
.bottom.end::after {
  right: var(--abs-12);
  left: unset;
}

.top.center::after,
.bottom.center::after {
  left: 50%;
  transform: translate(-50%, -50%);
}

.top::after,
.bottom::after {
  transform: translateY(-50%);
}

.bottom {
  top: calc(100% + var(--abs-8));
}

.bottom::after {
  top: calc(-1 * var(--padding));
  border-bottom-color: var(--black-a75);
}

.right.center,
.left.center {
  top: 50%;
  transform: translateY(-50%);
}

.right.start,
.left.start {
  top: 0;
}

.right.end,
.left.end {
  bottom: 0;
}

.right.start::after,
.left.start::after {
  top: var(--abs-12);
  transform: translateX(-50%);
}

.right.end::after,
.left.end::after {
  bottom: var(--abs-12);
  transform: translateX(-50%);
}

.right.center::after,
.left.center::after {
  top: 50%;
  transform: translate(-50%, -50%);
}

.right {
  right: 0%;
  transform: translateX(calc(100% + var(--abs-8)));
}

.right.center {
  transform: translate(calc(100% + var(--abs-8)), -50%);
}

.right::after {
  left: calc(-1 * var(--padding));
  border-right-color: var(--black-a75);
}

.left {
  right: calc(100% + var(--abs-8));
}

.left::after {
  left: calc(100% + var(--padding));
  border-left-color: var(--black-a75);
}

.stickyTooltip .tooltipText {
  visibility: visible;
  opacity: 1;
}

.tooltipContainer .tooltipText.hideTooltip,
.stickyTooltip .tooltipText.hideTooltip {
  visibility: hidden;
  opacity: 0;
}
.delayedTooltip {
  transition-delay: 1s;
}
