.c-input {
  background: var(--white);
  border: 1px solid var(--grey-500);
  font-size: var(--font-size--h-tiny);
  font-weight: normal;
  color: var(--grey-700);
  height: 36px;
  padding: var(--gutter--small);
}

.c-input--wider {
  width: 350px;
}

.c-input--big {
  height: 44px;
  font-size: var(--font-size--t-small);
}

.c-input--attached-right {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border-right-width: 0;
}
