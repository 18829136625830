.filterContainer {
  display: flex;
  align-items: center;
}
.filterBar {
  padding: var(--abs-8) var(--abs-16);
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-color: var(--grey-300);
  border-width: 0 0 1px;
  align-items: center;
}
