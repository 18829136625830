.cModalContainer {
  max-width: 62vw;
  max-height: 89vh;
}

.cModalContainerContent {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 48vh;
}

.cModalContainerFooterEmpty {
  height: 18px;
}
