.contextMenuGridCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contextMenuButton {
  border: unset;
  background-color: unset;
  display: flex;
  align-items: center;
  z-index: var(--z-index-5);
}
.contextMenuButton:hover,
.contextMenuButtonActive {
  background-color: var(--grey-100) !important;
  border-radius: var(--rel-100);
}
