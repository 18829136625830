.u-transform--rotate-180deg {
  transform: rotate(180deg);
}

.u-transform--rotate-90deg {
  transform: rotate(90deg);
}

.u-transform--rotate-minus-90deg {
  transform: rotate(-90deg);
}

.u-transform--rotate-45deg {
  transform: rotate(45deg);
}

.u-transform--rotate-135deg {
  transform: rotate(135deg);
}
