.controlPanel {
  display: flex;
  align-items: center;
  height: 52px;
  line-height: 52px;
  background-color: var(--blue-200);
  justify-content: space-between;
  padding: var(--abs-8) var(--gutter);
  margin-top: var(--gutter);
}
