.c-header-tab__search {
  margin-left: auto;
  width: 261px;
}

.c-header-tab__search:focus {
  /*
        TODO colours var(--blue-300) and var(--blue-400) should be reviewed due to the default style for focus depends on the browser
        and in the OS. This means that this colour does not fit in all the browsers/OS we are mantaining. Also 
        this should be added to variables.
    */
  box-shadow: 0 0 0 3px var(--blue-300);
  border: 2px solid var(--blue-400);
}
