.dashboardHeader {
  display: flex;
  margin-top: var(--gutter);
  margin-right: var(--gutter);
  margin-left: var(--gutter);
}

.menu {
  top: 5px;
  margin-left: var(--gutter--small);
  position: relative;
}

.menuItem {
  display: flex;
  align-items: center;
}

.menuItemIcon {
  margin-right: var(--gutter--t-small);
}

.onboardingTooltip {
  position: relative;
  right: 68px;
  top: 5px;
}

.readMoreLink {
  color: var(--white);
}
